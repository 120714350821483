import * as React from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as CarrierRatesSettingsStore from '../store/CarrierRatesSettings';
import * as TabsStore from "../store/Tabs";
import Moment from 'moment';

import * as ForgotPasswordStore from '../store/ForgotPassword';
import * as UserStore from '../store/Users';
import Loader from './Loader';

import { getText } from '../utils/langTexts';
import DialogModal from './DialogModal';
import Table, { Column } from './Table';
import ModeratorAgentSearchDetails from './ModeratorAgentSearchDetails';
import { revertColors } from '../styles/colors';
import { searchContainerStyle, searchFieldStyle, filterForm, formContainerStyle, inputStyleModerator, labelStyle, buttonStyle } from '../styles/moderatorStyles';
import { ApplicationState } from '../store';
import CenteredContentTabs from './CenteredContentTabs';
import { CreateModeratorUserForm, UpdateModeratorUserForm } from './ModeratorUsersForm';

type ModeratorUserProps = ModeratorUsersOwnProps
    & UserStore.UserState
    & typeof UserStore.actionCreators
    & typeof ForgotPasswordStore.actionCreators
    & typeof TabsStore.actionCreators;


interface ModeratorUsersOwnProps {
    subscriptions: Array<Api.SubscriptionModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    carriers: { [id: number]: Api.CarrierModel }
    chargeNames: { [id: number]: Api.ChargeNameModel }
    currencies: { [id: number]: Api.CurrencyModel }
}

export const getFirms = (subscriptions: Array<Api.SubscriptionModel>): Array<Api.FirmModel> => {
    return _.uniqBy(subscriptions
        .filter(su => su.subscriptionType === "Okargo")
        .filter(x => x.agency.company.companyGroup)
        .map(x => x.agency.company.companyGroup).concat(
            subscriptions.filter(so => so.subscriptionType === "Okargo")
                .map(so => so.agency)
                .concat(subscriptions.filter(so => so.subscriptionType === "Okargo")
                    .map(so => so.agency.company))), x => x.firmId);
}

const partContainerStyle = {};





class ModeratorUsers extends React.Component<ModeratorUserProps, {}> {



    componentWillMount() {
        this.props.requestUsers(new Date().getTime());
    }

    componentWillReceiveProps(nextProps: ModeratorUserProps) {
    }

    public render() {

        console.log(this.props.selectedId, "has selected");
        return (
            <div style={partContainerStyle}>
                <div>
                    <Table
                        tableKey={"modChargesAgents"}
                        data={this.props.entities}
                        columns={[
                            {
                                header: getText("GenCompanyGroup"),
                                id: 'group',
                                accessor: (d: Api.UserManagementModel) => d.subscriptionId ? d.subscriptionFirms.find(x => x.subscriptionId == d.subscriptionId)?.agency?.company?.companyGroup?.name
                                    : (d.subscriptionFirms[0] ?? null).agency?.company?.companyGroup?.name
                            },
                            {
                                header: getText("GenCompany"),
                                id: 'company',
                                accessor: (d: Api.UserManagementModel) => d.subscriptionId ? d.subscriptionFirms.find(x => x.subscriptionId == d.subscriptionId)?.agency?.company?.name
                                    : (d.subscriptionFirms[0] ?? null).agency?.company?.name
                            },
                            {
                                header: getText("GenAgency"),
                                id: 'agency',
                                accessor: (d: Api.UserManagementModel) => d.subscriptionId ? d.subscriptionFirms.find(x => x.subscriptionId == d.subscriptionId)?.agency?.name
                                    : (d.subscriptionFirms[0] ?? null)?.agency?.name
                            },
                            {
                                header: getText("QtnContFirstName"),
                                id: 'firstName',
                                accessor: (d: Api.UserManagementModel) => d.firstName
                            },

                            {
                                header: getText("QtnContLastName"),
                                id: 'lastName',
                                accessor: (d: Api.UserManagementModel) => d.lastName
                            },


                            {
                                header: getText("LogEmail"),
                                id: 'email',
                                accessor: (d: Api.UserManagementModel) => d.account.email
                            },
                            {
                                header: getText("MtdUsersCreated"),
                                id: 'creationDate',
                                accessor: (d: Api.UserManagementModel) => d.creationDate,
                                cell: (d: any) => <div>
                                    {Moment(d).format("DD/MM/YYYY")}
                                </div>
                            },
                            {
                                header: getText("MtdUsersExpired"),
                                id: 'expirationDate',
                                accessor: (d: Api.UserManagementModel) => d.account.expirationDate,
                                cell: (d: any) => <div>
                                    {d != null ? Moment(d).format("DD/MM/YYYY") : ""}
                                </div>
                            },
                            {
                                header: getText("MtdHasAcces"),
                                id: 'subscriptions',
                                accessor: (d: Api.UserManagementModel) => _.uniq(d.subscriptionFirms.filter(s => s.subscriptionType == 'Okargo').map(x => x.agency?.name)).join(","),

                            },

                            {
                                header: "",
                                id: 'actions',
                                accessor: (d: Api.UserManagementModel) => d,
                                cell: d => {
                                    return <div>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {

                                       
                                            this.props.updateUserSelectedId(d.clientId);
                                            this.props.selectTab("userCreation", "edit");
                                        }}>{getText("GenEdit")}</a>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                            if (window.confirm(getText("GenConfirmQuestion"))) {
                                                this.props.requestGeneratePassword(new Date().getTime(),d.clientId);
                                            }
                                        }}>Reset password</a>
                                        <a style={{ cursor: "pointer", margin: "0px 5px" }} onClick={(e) => {
                                            if (window.confirm(getText("GenConfirmQuestion"))) {
                                                this.props.requestDeactivateUser(new Date().getTime(), d.clientId);
                                            }
                                        }}>{getText("MtdDeactivate")}</a>
                                    </div>
                                }
                            },
                        ]}
                        rowsPerPage={8}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        showPagination={true}
                        noDataText="No results"
                    />
                </div>
                <div>
                    <CenteredContentTabs
                        tabKey={"userCreation"}
                        data={[
                            {
                                title: getText("GenCreate"),
                                tabKey: "create",
                                tabContent: <div style={{ padding: 10 }}>
                                    <h3>Create user</h3>
                                    <div style={{ maxWidth: 1000 }}>
                                        <CreateModeratorUserForm
                                            mode={"Create"}
                                            onSubmit={(values) => {

                                                return this.props.requestCreateUser(new Date().getTime(), values) as any;

                                            }} />
                                    </div>
                                </div>
                            },
                            {
                                title: getText("GenEdit"),
                                tabKey: "edit",
                                tabContent: <div style={{ padding: 10 }}>
                                    <div style={{ maxWidth: 1000 }}>
                                        <h3>Update user</h3>
                                        {this.props.selectedId
                                            && <div>
                                                <UpdateModeratorUserForm
                                                    mode={"Update"}
                                                    onSubmit={(values) => {
                                                        return this.props.requestUpdateUser(new Date().getTime(), values) as any;

                                                    }} />
                                            </div>}
                                    </div>
                                </div>
                            }
                        ]}
                    ></CenteredContentTabs>
                </div>

            </div>
        );
    }
}



//selectChargeLocation: (location: Api.LocationModel, source: Source) => void;
//updateChargeLocationInputText: (value: string, source: Source) => void;
export default connect(
    (state: ApplicationState) => ({
        ...state.users,
        subscriptions: state.account.currentUser.clientModel
            ? state.account.currentUser.clientModel.subscriptions
            : [],
    } as any),
    {
        ...UserStore.actionCreators,
        ...TabsStore.actionCreators,
        ...ForgotPasswordStore.actionCreators
    })(ModeratorUsers) as any