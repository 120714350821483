import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import OfferReminder from './OfferReminder';
import * as SelectionStore from '../store/Selection';
import * as AskRatesStore from '../store/AskRates';
import { getCarrierOfferKey } from '../utils/utils';
import { getText } from '../utils/langTexts';
import { revertColors } from '../styles/colors';
import Button from './Button';
import * as Colors from "../styles/colors";

type AskRatesProps = AskRatesOwnProps
    & typeof AskRatesStore.actionCreators;

interface AskRatesOwnProps {
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionStore.CarrierOfferState;
    carriers: { [id: number]: Api.CarrierModel };
}

const fieldStyle = { marginBottom: 10, marginTop: 10 } as React.CSSProperties;
const containerStyle = {} as React.CSSProperties;
const innerStyle = { padding: 10 } as React.CSSProperties;
const btnStyle = { margin: 5 } as React.CSSProperties;
const descStyle = { ...fieldStyle, textAlign: "center" } as React.CSSProperties;
const btnsStyle = { ...fieldStyle, textAlign: "center" } as React.CSSProperties;

class AskRates extends React.Component<AskRatesProps, {}> {
    constructor(props) {
        super(props);
    }

    public render() {
        return <div style={containerStyle}>
            <div style={innerStyle}>
                {this.props.carrierOffer &&
                    <div style={fieldStyle}>
                        <OfferReminder
                            carrierOffer={this.props.carrierOffer}
                            carrierOfferState={this.props.carrierOfferState}
                            carriers={this.props.carriers} />
                    </div>}
                <div style={descStyle}>
                    {getText("SlcOfferAskRatesDesc")}
                </div>
                <div style={btnsStyle}>
                    {this.props.carrierOffer && this.props.carriers[this.props.carrierOffer.carrierId].askRatesState !== "Hide" &&
                        <Button style={{ ...btnStyle,
                            color: "white",
                            backgroundColor: Colors.baseColors.lightBlue
                        }}
                            disabled={!this.props.carriers[this.props.carrierOffer.carrierId].askRatesLink}
                            onClick={(e) => {
                                window.open(this.props.carriers[this.props.carrierOffer.carrierId].askRatesLink, "_blank");
                                this.props.closeAskRates();
                                e.preventDefault();
                            }}>
                            {getText("SlcOfferAskRatesGoByWebSite")}
                        </Button>}
                    <Button style={{ ...btnStyle, 
                        color: "white",
                        backgroundColor: Colors.baseColors.lightBlue
                    }}
                        disabled={this.props.carrierOffer
                            && !this.props.carriers[this.props.carrierOffer.carrierId].askRatesByEmail}
                        onClick={(e) => {
                            this.props.openEmailAskRates();
                            e.preventDefault();
                        }}>
                        {getText("SlcOfferAskRatesGoByEmail")}
                    </Button>
                </div>
            </div>
        </div>
    }
}

export default connect((state: ApplicationState) => {
    return {
        carrierOffer: state.selection.selection.carrierOffers
            .find(co => getCarrierOfferKey(co) === state.askRates.carrierOfferId),
        carrierOfferState: state.selection.carrierOfferStates[state.askRates.carrierOfferId],
        carriers: state.seed.carriers
    } as any;
}, AskRatesStore.actionCreators)(AskRates) as any