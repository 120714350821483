import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as Colors from "../styles/colors";
import * as DocumentStore from "../store/Document";

import DescriptionIcon from '@material-ui/icons/Description';

import InfoIcon from '@material-ui/icons/Info';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";


type HelpCenterProps = HelpCenterOwnProps
    & DocumentStore.DocumentState
    & typeof DocumentStore.actionCreators;

interface HelpCenterOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
}

const containerStyle = {
    fontFamily: "Calibri",
    margin: "20px"
};

const iconStyle = {
    color: Colors.baseColors.lightBlue,
    verticalAlign: "bottom"
} as React.CSSProperties;


const linkStyle = {
    cursor: "pointer",
    color: Colors.baseColors.lightBlue,
    paddingLeft:"15px"

} as React.CSSProperties;

class HelpCenter extends React.Component<HelpCenterProps, {}> {

    onPropsUpdate(props: HelpCenterProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestFirmDocuments(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: HelpCenterProps) {
        this.onPropsUpdate(nextProps);
    }
    public render() {
        return (
            <div style={containerStyle}>
                <h1>  <InfoIcon style={{color:Colors.baseColors.lightBlue}} />Help center</h1>
                <div style={{
                    marginRight: "12px",
                    position: "relative",
                    top: "2px",
                }} />
                <div>
                    <h3>Written guides</h3>

                    <div >
                        <a style={{ ...linkStyle }} onClick={(e) => {
                            if (!this.props.downloadStates["quickStart"]
                                || !this.props.downloadStates["quickStart"].isLoading) {
                                this.props.downloadQuickstart(new Date().getTime(), "en");
                            }
                        }}><DescriptionIcon style={iconStyle} />Quickstart</a><span> - All you need to start using Okargo</span>
                    </div>
                    <div >
                        <a style={linkStyle} onClick={(e) => {
                            if (!this.props.downloadStates["guideCrawlers"]
                                || !this.props.downloadStates["guideCrawlers"].isLoading) {
                                this.props.downloadGuideCrawlers(new Date().getTime(), "en");
                            }
                        }}><DescriptionIcon style={iconStyle} />Setting up connections via webcrawlers</a> - How to get online quotes from non-API connections
                    </div>


                    <h3>Video tutorials</h3>
                    <div >
                        <a style={linkStyle} target="_blank" href="https://www.youtube.com/watch?v=Npv0m-PqGpQ"
                        ><PlayArrowIcon style={iconStyle} />How to set up a web crawler connection per user</a> - How to set up your own connections
                    </div>
                    <div >
                        <a target="_blank" href="https://youtu.be/Fz3G4Q3gZCI"
                            style={linkStyle} ><PlayArrowIcon style={iconStyle} />How to set up a web crawler connection per company or office</a> - How to set up connections for you and your collaborators
                    </div>



                </div>
            </div>
        );
    }
}



export default connect((state: ApplicationState) => ({
    ...state.document,

    carriers: state.seed.carriers,

}),
    DocumentStore.actionCreators)(HelpCenter) as any