import {FormField} from "./Form/FormField";
import React, {useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {RatesExtractionModel} from "../api/rtkApi";
import {smInputStyle, smLabelStyle} from "../styles/baseStyles";
import LocationSelect from "./LocationSelect";
import {getLocationName} from "../utils/utils";
import Select from "./Select";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {carriersSelector, currenciesSelector} from "../slices/ratesExtraction";
import _ from "lodash";
import {containerTypeOptions} from "./Criteria";
import {getRatesTypeOptions} from "./CarrierCarrierRatingForm";
import CheckBox from "./CheckBox";
import {Delete} from "@material-ui/icons";
import * as Notifications from "react-notification-system-redux";
import {getText} from "../utils/langTexts";
import Button from "./Button";

type RatesExtractionFormType = "Create" | "Update";

export const ratesSourceOptions = () => [
    { label: "Integrated PL", value: "IntegratedPl" },
    { label: "Online quotes", value: "Ocq" },
    { label: "Self upload", value: "SelfUpload" },
];
export const applicationOptions = () => [
    { label: "Origin", value: "Origin" },
    { label: "Destination", value: "Destination" },
    { label: "Prepaid", value: "Prepaid" },
    { label: "Collect", value: "Collect" },
];
export const validityTypeOptions = () => [
    { label: "Grid", value: "Grid" },
    { label: "Spot", value: "Spot" }
]
export function RatesExtractionForm({ type, defaultValues, onSubmit, disabled  }
                             : {
    type: RatesExtractionFormType;
    defaultValues?: RatesExtractionModel;
    onSubmit: (values: RatesExtractionModel) => Promise<any>;
    disabled?: boolean;
}) {
    const [polSelected, updatePolSelected] = useState(null);
    const [podSelected, updatePodSelected] = useState(null);
    const [carrierSelected, updateCarrierSelected] = useState(null);
    const carriers = useAppSelector(carriersSelector);
    const currencies = useAppSelector(currenciesSelector);
    const dispatch = useAppDispatch();
    const {
        register: register
        , handleSubmit: handleSubmit
        , formState: formState
        , watch: watch
        , reset: reset
        , resetField: resetField
        , control: formControl,
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });

    const handleOnSubmit = (data: RatesExtractionModel) => {
        let fnc = onSubmit(data);
        if(fnc && fnc.then){
            fnc.then(x => {
                if(!x.error){
                    reset();
                } else {
                    dispatch(Notifications.success({ message: "An error has occured submitting the form", title: "Error", position: "tc" }) as any);
                }
            });
        }
    };
    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}
              style={{
                  display: "grid",
                  gridTemplateRows: "auto auto auto auto",
                  gridTemplateColumns: "auto 1fr auto",
                  gap: "calc(0.3vw + 3px)",
              }}>
            <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...smLabelStyle}}>Name:</div>
            <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"name"}>
                <input type={"text"}
                       style={{...smInputStyle}} {...register("name", {required: "This field is required"})} />
            </FormField>
            <div style={{gridRow: "2 / 3", gridColumn: "1 / 2", ...smLabelStyle}}>POLs:</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"ratesExtractionPols"}>
                <Controller name={"ratesExtractionPols"}
                            render={({field}) => <div
                                style={{display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: "calc(0.5vw + 5px)" }}>
                                <LocationSelect value={polSelected}
                                                locationSearchType={"OnlyPorts"}
                                                inputKey={"ratesExtractionPols"}
                                                onChange={(location) => {
                                                    if (!location || field.value.some(x => x.portId === location.locationId)) {
                                                        return;
                                                    }
                                                    field.onChange((field.value || []).concat([{
                                                        portId: location.locationId,
                                                        port: location
                                                    }]));
                                                    updatePolSelected(null);
                                                }}/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    columnGap: "calc(0.5vw + 5px)"
                                }}>
                                    {field.value.map(pol => <div key={pol.portId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {getLocationName(pol.port)}
                                        <Delete style={{ cursor: "pointer" }} onClick={(e) => {
                                            field.onChange((field.value || []).filter(x => x.portId !== pol.portId));
                                        }} />
                                    </div>)}
                                </div>
                            </div>}
                            control={formControl} rules={{ validate: v => !v || v.length === 0 ? getText("GenOneRequired") : undefined }} defaultValue={[]}/>
            </FormField>
            <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", ...smLabelStyle}}>PODs:</div>
            <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"ratesExtractionPods"}>
                <Controller name={"ratesExtractionPods"}
                            render={({field}) => <div
                                style={{display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: "calc(0.5vw + 5px)" }}>
                                <LocationSelect value={podSelected}
                                                inputKey={"ratesExtractionPods"}
                                                locationSearchType={"OnlyPorts"}
                                                onChange={(location) => {
                                                    if (!location || field.value.some(x => x.portId === location.locationId)) {
                                                        return;
                                                    }
                                                    field.onChange((field.value || []).concat([{
                                                        portId: location.locationId,
                                                        port: location
                                                    }]));
                                                    updatePodSelected(null);
                                                }}/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    columnGap: "calc(0.5vw + 5px)"
                                }}>
                                    {field.value.map(pod => <div key={pod.portId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {getLocationName(pod.port)}
                                        <Delete style={{ cursor: "pointer" }} onClick={(e) => {
                                            field.onChange((field.value || []).filter(x => x.portId !== pod.portId));
                                        }} />
                                    </div>)}
                                </div>
                            </div>}
                            control={formControl} rules={{ validate: v => !v || v.length === 0 ? getText("GenOneRequired") : undefined }} defaultValue={[]}/>
            </FormField>
            <div style={{gridRow: "4 / 5", gridColumn: "1 / 2", ...smLabelStyle}}>Carriers:</div>
            <FormField style={{gridRow: "4 / 5", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"ratesExtractionCarriers"}>
                <Controller name={"ratesExtractionCarriers"}
                            render={({field}) => <div
                                style={{display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: "calc(0.5vw + 5px)" }}>
                                <Select options={_.sortBy(_.values(carriers), x => x.name).map(x => ({label: x.name, value: x.carrierId}))}
                                        search={true}
                                        value={carrierSelected}
                                        style={{ minWidth: 240 }}
                                        onChange={(value) => {
                                            if (field.value.some(x => x.carrierId === value)) {
                                                return;
                                            }
                                            field.onChange((field.value || []).concat([{
                                                carrierId: value
                                            }]));
                                            updateCarrierSelected(null);
                                        }}/>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    columnGap: "calc(0.5vw + 5px)"
                                }}>
                                    {!field.value || field.value.length === 0 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        All carriers
                                    </div>}
                                    {field.value.map(ca => <div key={ca.carrierId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {carriers[ca.carrierId].name}
                                        <Delete style={{ cursor: "pointer" }} onClick={(e) => {
                                            field.onChange((field.value || []).filter(x => x.carrierId !== ca.carrierId));
                                        }} />
                                    </div>)}
                                </div>
                            </div>}
                            control={formControl} defaultValue={[]}/>
            </FormField>
            <div style={{gridRow: "5 / 6", gridColumn: "1 / 2", ...smLabelStyle}}>Container type:</div>
            <FormField style={{gridRow: "5 / 6", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"containerType"}>
                <Controller name={"containerType"}
                            render={({field}) => <Select options={containerTypeOptions()}
                                                         value={field.value}
                                                         onChange={(value) => field.onChange(value)}/>}
                            control={formControl} 
                            defaultValue={undefined} />
            </FormField>
            <div style={{gridRow: "6 / 7", gridColumn: "1 / 2", ...smLabelStyle}}>Rates type:</div>
            <FormField style={{gridRow: "6 / 7", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"ratesType"}>
                <Controller name={"ratesType"}
                            render={({field}) => <Select options={[{ label: "All", value: null }].concat(getRatesTypeOptions())}
                                                         value={field.value}
                                                         onChange={(value) => field.onChange(value)}/>}
                            control={formControl}
                            defaultValue={undefined} />
            </FormField>
            <div style={{gridRow: "7 / 8", gridColumn: "1 / 2", ...smLabelStyle}}>Rates source:</div>
            <FormField style={{gridRow: "7 / 8", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"ratesSourceFlag"}>
                <Controller name={"ratesSourceFlag"}
                            render={({field}) => <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    columnGap: "calc(0.5vw + 5px)"
                                }}>
                                {ratesSourceOptions().map(opt =>
                                    <CheckBox value={field.value.some(x => x === opt.value)}
                                              onChange={(value) => {
                                                  if (!value && field.value.some(x => x === opt.value)) {
                                                      field.onChange((field.value || []).filter(x => x !== opt.value))
                                                  } else {
                                                      field.onChange((field.value || []).concat([opt.value as any]));
                                                  }
                                              }}
                                              label={opt.label}/>)}
                            </div>}
                            control={formControl} defaultValue={[]}/>
            </FormField>
            <div style={{gridRow: "8 / 9", gridColumn: "1 / 2", ...smLabelStyle}}>Validity type:</div>
            <FormField style={{gridRow: "8 / 9", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"validityType"}>
                <Controller name={"validityType"}
                            render={({field}) => <Select options={[{ label: "All", value: null }].concat(validityTypeOptions())}
                                                         value={field.value}
                                                         onChange={(value) => field.onChange(value)}/>}
                            control={formControl}
                            defaultValue={undefined} />
            </FormField>
            <div style={{gridRow: "9 / 10", gridColumn: "1 / 2", ...smLabelStyle}}>Surcharges:</div>
            <FormField style={{gridRow: "9 / 10", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"applicationFlag"}>
                <Controller name={"applicationFlag"}
                            render={({field}) => <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    columnGap: "calc(0.5vw + 5px)"
                                }}>
                                {applicationOptions().map(opt =>
                                    <CheckBox value={field.value.some(x => x === opt.value)}
                                              onChange={(value) => {
                                                  if (!value && field.value.some(x => x === opt.value)) {
                                                      field.onChange((field.value || []).filter(x => x !== opt.value))
                                                  } else {
                                                      field.onChange((field.value || []).concat([opt.value as any]));
                                                  }
                                              }}
                                              label={opt.label}/>)}
                            </div>}
                            control={formControl} defaultValue={[]}/>
            </FormField>
            <div style={{gridRow: "10 / 11", gridColumn: "1 / 2", ...smLabelStyle}}>All in currency:</div>
            <FormField style={{gridRow: "10 / 11", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"currencyId"}>
                <Controller name={"currencyId"}
                            render={({field}) => <Select options={_.values(currencies).map(cu => ({ label: cu.name, value: cu.currencyId }))}
                                                         value={field.value}
                                                         onChange={(value) => field.onChange(value)}/>}
                            control={formControl}
                            defaultValue={1} />
            </FormField>
            <div style={{
                gridRow: "11 / 12",
                gridColumn: "3 / 4",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "0.5vw"
            }}>
                <Button type={"submit"} disabled={!formState.isValid || disabled}>
                    {type === "Create" ? "Create" : "Update"}
                </Button>
            </div>
        </form>
    );
}