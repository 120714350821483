import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as _ from 'lodash';
import * as SelectionStore from '../store/Selection';
import * as CriteriaStore from '../store/Criteria';
import Throttle from '../utils/throttleHandler';
import { connect } from 'react-redux';
import Slider from './Slider';
import Select from './Select';
import Button from './Button';
import { Guid, getSubscription } from '../utils/utils';
import CheckBox from './CheckBox'
import { ApplicationState } from '../store';
import * as selectionConnect from '../connects/selectionConnect';
import { getText } from "../utils/langTexts";
import * as Colors from '../styles/colors';
import CriteriaSizeType from './CriteriaSizeType';
import InputNumber from './InputNumber';
import ExpansionPanel from "./ExpansionPanel"
import InputText from './InputText';
import { Add, Remove, Close } from '@material-ui/icons';
import { containerTypeOptions } from './CriteriaForm';
import ChargeCriteriaInput from './ChargeCriteriaInput';
import ExpensionPanel from './ExpansionPanel';
import { isSmallScreen } from '../utils/screenUtils';
import WeightPicker from './WeightPicker';

type SelectionFilterProps = SelectionFilterOwnProps
    & SelectionFilterExternalProps
    & typeof SelectionStore.actionCreators
    & typeof CriteriaStore.actionCreators;

interface SelectionFilterOwnProps {
    criteria: Api.CriteriaModel;
    criteriaLoaded: Api.CriteriaModel;
    carrierOffers: Array<Api.CarrierOfferModel>;
    filterModel: SelectionStore.SelectionFilterModel;
    currencies: { [id: number]: Api.CurrencyModel };
    currencyId: number;
    filterOptions: SelectionStore.SelectionFilterOptions;
    sizeTypes: { [id: number]: Api.SizeTypeModel }
    subscription: Api.SubscriptionModel;
    carriers: { [id: number]: Api.CarrierModel };
    carrierGroups: { [id: number]: Api.CarrierGroupModel };
    advContainerOpened: boolean;
}

type SelectionFilterExternalProps = {
    criteriaType: Api.CriteriaModelTypeEnum;
}

interface SelectionFilterState {
    ratesTypeName: string;
}

interface CarrierSelected {
    carrierId: number;
    isSelected: boolean;
}

interface CarrierGroupSelected {
    carrierGroupId: number;
    isSelected: boolean;
    isOpen: boolean;
    carriers: Array<CarrierSelected>;
}


const ratesTypeOptions = (): Array<{ label: string; value: SelectionStore.RatesTypeFilter }> => [
    { value: "AllRates", label: getText("SlcFilterRatesAll") },
    { value: "Fak", label: getText("SlcFilterRatesOnlyFak") },
    { value: "Special", label: getText("SlcFilterRatesOnlySpecial") }
];

const titleStyle: React.CSSProperties = {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: "bold"
};

const checkboxLabelStyle: React.CSSProperties = {
    fontSize: 14
}

const inputContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}

const getAdvBtn = (): React.CSSProperties => {
    if (isSmallScreen()) {
        return {
            fontWeight: 700,
            width: 120,
            height: 28
        };
    } else {
        return {
            fontWeight: 700,
            width: 120,
            height: 35
        };
    }
}


const advBtnContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "10px 0px",
    width: "100%"
}

const advIconStyle: React.CSSProperties = {
    fontSize: 14,
    fontWeight: "bold"
}

const additionalLabelStyle: React.CSSProperties = { fontSize: 18, fontWeight: 500 };
class SelectionFilter extends React.Component<SelectionFilterProps, SelectionFilterState> {
    private guid: string;

    constructor(props) {
        super(props);
        this.guid = Guid.newGuid();
        this.state = {
            ratesTypeName: "",
        };
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: SelectionFilterProps) {
    }

    handleRatesTypeNamesChange(value: string) {
        this.setState({ ratesTypeName: value });
        Throttle.throttle(this.guid, () => {
            this.props.filterUpdateRatesTypeName(this.state.ratesTypeName);
        }, 500);
    }

    get selectedCtrOptions(): number {
        if (this.props.criteria.type === "Lcl")
            return 0;

        return (this.props.criteria.chargeCriterias.some(x => x === "Goh") ? 1 : 0)
            + (this.props.criteria.chargeCriterias.some(x => x === "GohDouble") ? 1 : 0)
            + (this.props.criteria.chargeCriterias.some(x => x === "Hazardous") ? 1 : 0)
            + (this.props.criteria.chargeCriterias.some(x => x === "Owned") ? 1 : 0);
    }

    get carriersSelected(): Array<CarrierSelected> {
        return _.values(this.props.carriers)
            .filter(x => this.props.carrierOffers.some(y => y.carrierId === x.carrierId))
            .map(ca => {
                return {
                    carrierId: ca.carrierId,
                    isSelected: this.props.filterModel.carriersSelected[ca.carrierId] !== false
                }
            });
    }

    get carrierGroupsSelected(): Array<CarrierGroupSelected> {
        let carriersSelected = this.carriersSelected;
        let carrierGroupsSelected = [] as Array<CarrierGroupSelected>
        _.values(this.props.carrierGroups).forEach(cg => {
            let carriers = carriersSelected
                .filter(cs => this.props.carriers[cs.carrierId].enabled
                    && cg.carrierGroupId === this.props.carriers[cs.carrierId].carrierGroupId);

            carrierGroupsSelected.push({
                carrierGroupId: cg.carrierGroupId,
                isOpen: this.props.filterModel.carrierGroupsOpened[cg.carrierGroupId],
                isSelected: carriers.some(cs => cs.isSelected),
                carriers: carriers
            });
        });
        return carrierGroupsSelected;
    }

    public render() {

        var mainCriteria = _.sortBy(this.props.criteria.criteriaSizeTypes, x => this.props.sizeTypes[x.sizeTypeId].position).slice(0, 3);
        var additonalCriteria = _.sortBy(this.props.criteria.criteriaSizeTypes, x => this.props.sizeTypes[x.sizeTypeId].position).slice(3);

        return (
            <div>
                <div style={{ padding: "30px 15px" }}>
                    <div style={{ ...titleStyle }}>Containers</div>
                    <div style={{ marginBottom: 10 }}>
                        {this.props.criteriaType === "Fcl" && <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 20
                            }}>
                                <div style={{ flex: "0 0 auto", minWidth: 150, marginRight: 12 }}>
                                    <div style={{ ...additionalLabelStyle }}>{getText("CrtContainerType")}:</div>
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    <Select
                                        options={containerTypeOptions()}
                                        value={this.props.criteria.containerType}
                                        onChange={(value) => this.props.selectContainerType(value)} />
                                </div>                           
                            </div>
                            <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "strech", flexWrap: "wrap", marginBottom: 20 }}>
                                    {(this.props.criteria.criteriaSizeTypes ?
                                        mainCriteria
                                            .map((cs) =>
                                                <div key={cs.sizeTypeId} style={{ display: "flex", flexDirection: "column", alignItems: "strech", minWidth: 105, marginBottom: 10 }}>
                                                    <CriteriaSizeType
                                                        key={cs.sizeTypeId}
                                                        style={{}}
                                                        buttonStyle={isSmallScreen()
                                                            ? { height: 22, width: 22, borderRadius: 7 }
                                                            : { height: 26, width: 26 }}
                                                        iconStyle={{ fontSize: 14 }}
                                                        sizeTypes={this.props.sizeTypes}
                                                        sizeTypeId={cs.sizeTypeId}
                                                        value={cs.number}
                                                        onChange={(value) => this.props.updateSizeTypeNumber(cs.sizeTypeId, value)}
                                                        min={0}
                                                        max={this.props.criteria.containerPrice ? 1 : 10} />
                                                    {cs.number > 0 && <WeightPicker
                                                        sizeTypes={this.props.sizeTypes}
                                                        criteriaSize={cs}
                                                        showLabel={false}
                                                        iconStyle={{ fontSize: 16 }}
                                                        onChange={(value) => this.props.updateContainerCriteria(value)}
                                                    />}
                                                </div>
                                            ) : <div style={{ flex: 1 }}></div>)}
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    {this.props.criteria.criteriaSizeTypes 
                                        && !this.props.criteria.criteriaSizeTypes.some(x => x.number > 0)
                                        && getText("CrtContainerRequired")}
                                </div>
                            </div>
                            <ExpensionPanel expanded={this.props.advContainerOpened}
                                onChange={(val) => {
                                    if (val) {
                                        this.props.openAdvContainer();
                                    } else {
                                        this.props.closeAdvContainer();
                                    }
                                }}
                                head={< div style={{ ...advBtnContainer }}>
                                    <Button
                                        type="button"
                                        style={{ ...getAdvBtn() }}
                                        onClick={(e) => {

                                            if (!this.props.advContainerOpened) {
                                                this.props.openAdvContainer();
                                            } else {
                                                this.props.closeAdvContainer();
                                            }

                                            if (e.preventDefault) {
                                                e.preventDefault();
                                            }
                                        }}>
                                        {getText("CrtOptions")} {this.props.advContainerOpened
                                            ? <Remove style={{ ...advIconStyle }} />
                                            : <Add style={{ ...advIconStyle }} />}
                                    </Button>
                                </div>}  >
                                <div style={{
                                    paddingTop: 6, width:"100%"
                                }}>

                                    <div style={{ padding: "10px 0px", display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: 10, alignItems: "top" }}>

                                        <div style={{ flex: "1 1 auto" }}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "top" }}>

                                                {(additonalCriteria.length > 0 ?
                                                    additonalCriteria
                                                        .map((cs) =>
                                                            <div key={cs.sizeTypeId} style={{ flex: 1, display: "flex", flexDirection: "column",alignItems:"top", minWidth:105}}>
                                                                <CriteriaSizeType
                                                                    key={cs.sizeTypeId}
                                                                    style={{}}
                                                                    buttonStyle={isSmallScreen()
                                                                        ? { height: 22, width: 22, borderRadius: 7 }
                                                                        : { height: 26, width: 26 }}
                                                                    iconStyle={{ fontSize: 14 }}
                                                                    sizeTypes={this.props.sizeTypes}
                                                                    sizeTypeId={cs.sizeTypeId}
                                                                    value={cs.number}
                                                                    onChange={(value) => this.props.updateSizeTypeNumber(cs.sizeTypeId, value)}
                                                                    min={0}
                                                                    max={this.props.criteria.containerPrice ? 1 : 9} />
                                                                {cs.number > 0 && <WeightPicker
                                                                    sizeTypes={this.props.sizeTypes}
                                                                    criteriaSize={cs}
                                                                    showLabel={false}
                                                                    iconStyle={{ fontSize: 16 }}
                                                                    onChange={(value) => this.props.updateContainerCriteria(value)}
                                                                />}
                                                            </div>
                                                        ) : <div style={{ flex: 1 }}></div>)}
                                            </div>
                                        </div>
                                        <div style={{ flex: " 1 1 auto", alignItems: "right" }}>
                                            <div style={{}}>
                                                <ChargeCriteriaInput
                                                    label={getText("GenHazardous")}
                                                    labelPlacement={"start"}
                                                    labelStyle={{ minWidth: 100 }}
                                                    chargeCriteria={"Hazardous"}
                                                    value={this.props.criteria.chargeCriterias}
                                                    onChange={(value) => this.props.updateChargeCriteria(value)} />
                                            </div>
                                            <div style={{}}>
                                                <ChargeCriteriaInput
                                                    label={getText("GenNor")}
                                                    labelPlacement={"start"}
                                                    labelStyle={{ minWidth: 100 }}
                                                    chargeCriteria={"Nor"}
                                                    value={this.props.criteria.chargeCriterias}
                                                    onChange={(value) => this.props.updateChargeCriteria(value)} />
                                            </div>
                                            <div style={{}}>
                                                <ChargeCriteriaInput
                                                    label={getText("GenContainerOwned")}
                                                    labelPlacement={"start"}
                                                    labelStyle={{ minWidth: 100 }}
                                                    chargeCriteria={"Owned"}
                                                    value={this.props.criteria.chargeCriterias}
                                                    onChange={(value) => this.props.updateChargeCriteria(value)} />
                                            </div>
                                            <div style={{}}>
                                                <ChargeCriteriaInput
                                                    label={getText("GenGoh")}
                                                    labelPlacement={"start"}
                                                    labelStyle={{ minWidth: 100 }}
                                                    chargeCriteria={"Goh"}
                                                    value={this.props.criteria.chargeCriterias}
                                                    onChange={(value) => this.props.updateChargeCriteria(value)} />
                                            </div>
                                            <div style={{}}>
                                                <ChargeCriteriaInput
                                                    label={getText("GenGohDouble")}
                                                    labelPlacement={"start"}
                                                    labelStyle={{ minWidth: 100 }}
                                                    chargeCriteria={"GohDouble"}
                                                    value={this.props.criteria.chargeCriterias}
                                                    onChange={(value) => this.props.updateChargeCriteria(value)} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ExpensionPanel>
                        </div>}
                        {this.props.criteriaType === "Lcl"
                            && (
                                <div>
                                    <div>Size</div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{
                                            flex: "0 0 auto",
                                            minWidth: 40,
                                            textAlign: "right",
                                            marginRight: 9
                                        }}>
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column", alignItems: "stretch"
                                        }}>
                                            <div style={{ flex: 1, marginBottom: 6 }}>
                                                <InputNumber
                                                    value={this.props.criteria.cbm}
                                                    unit={"CBM"}
                                                    style={{
                                                        width: 100,
                                                        flex: "0 0 auto",
                                                        fontSize: 16,
                                                    }}
                                                    unitStyle={{ fontSize: 16 }}
                                                    onChange={(value) => this.props.updateCbm(value)} />
                                            </div>
                                            <div style={{ flex: 1, marginBottom: 6 }}>
                                                <InputNumber
                                                    value={this.props.criteria.ton}
                                                    unit={"TON"}
                                                    style={{
                                                        width: 100,
                                                        flex: "0 0 auto",
                                                        fontSize: 16,
                                                    }}
                                                    unitStyle={{ fontSize: 16 }}
                                                    onChange={(value) => this.props.updateTon(value)} />
                                            </div>
                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                fontSize: 16
                                            }}>
                                                <div style={{ flex: "0 0 auto", marginRight: 6 }}>=</div>
                                                <div style={{ flex: 1, marginRight: 6, textAlign: "center" }}>
                                                    {Math.max(this.props.criteria.cbm, this.props.criteria.ton)}
                                                </div>
                                                <div style={{ flex: 1, marginRight: 6 }}>
                                                    WM
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                    {
                        (!this.props.subscription || this.props.subscription.subscriptionType !== "Agent")
                        && <div>
                            <div style={{ ...titleStyle }}>All-in rates including</div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                                <div style={{ flex: 1, ...inputContainerStyle, minWidth: 150 }}>
                                    <div>
                                        <CheckBox
                                            labelStyle={{ ...checkboxLabelStyle }}
                                            size={"small"}
                                            label={getText("CrtLoadingCharges")}
                                            onChange={(value) => this.props.selectLoadingcharge(value)}
                                            value={this.props.criteria.loadingCharge} />
                                    </div>
                                    {/*<div>*/}
                                    {/*    <CheckBox*/}
                                    {/*        labelStyle={{ ...checkboxLabelStyle }}*/}
                                    {/*        size={"small"}*/}
                                    {/*        label={getText("CrtPrepaidCharges")}*/}
                                    {/*        onChange={(value) => this.props.selectPrepaidcharge(value)}*/}
                                    {/*        value={this.props.criteria.prepaidCharge} />*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{ flex: 1, ...inputContainerStyle, minWidth: 150 }}>
                                    <div>
                                        <CheckBox
                                            labelStyle={{ ...checkboxLabelStyle }}
                                            size={"small"}
                                            label={getText("CrtUnLoadingCharges")}
                                            onChange={(value) => this.props.selectUnLoadingcharge(value)}
                                            value={this.props.criteria.unLoadingCharge} />
                                    </div>
                                    {/*<div>*/}
                                    {/*    <CheckBox*/}
                                    {/*        labelStyle={{ ...checkboxLabelStyle }}*/}
                                    {/*        size={"small"}*/}
                                    {/*        label={getText("CrtCollectCharges")}*/}
                                    {/*        onChange={(value) => this.props.selectCollectcharge(value)}*/}
                                    {/*        value={this.props.criteria.collectCharge} />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ ...titleStyle }}>Rates displayed</div>
                    <div style={{ ...inputContainerStyle }}>
                        <CheckBox
                            labelStyle={{ ...checkboxLabelStyle }}
                            size={"small"}
                            label={getText("CrtPriceByContainer")}
                            onChange={(value) => this.props.selectPriceByContainer(value)}
                            value={this.props.criteria.containerPrice} />
                    </div>
                    <div style={{ ...inputContainerStyle }}>
                        <CheckBox
                            labelStyle={{ ...checkboxLabelStyle }}
                            size={"small"}
                            label={getText("SlcFilterHideNoRates")}
                            onChange={(value) => this.props.filterSelectHideNoRates(value)}
                            value={this.props.filterModel.hideNoRates} />
                    </div>
                    <div style={{ ...inputContainerStyle }}>
                        <CheckBox
                            labelStyle={{ ...checkboxLabelStyle }}
                            size={"small"}
                            label={getText("SlcFilterOnlyBestTt")}
                            onChange={(value) => this.props.updateOnlyShowBestTt(value)}
                            value={this.props.filterModel.onlyShowBestTt} />
                    </div>
                    <div style={{ ...inputContainerStyle }}>
                        <CheckBox
                            labelStyle={{ ...checkboxLabelStyle }}
                            size={"small"}
                            label={getText("SlcFilterByRatesOffer")}
                            onChange={(value) => this.props.filterSelectByRatesOffer(value)}
                            value={this.props.filterModel.byRatesOffer} />
                    </div>
                    <div style={{ ...inputContainerStyle }}>
                        <CheckBox
                            labelStyle={{ ...checkboxLabelStyle }}
                            size={"small"}
                            label={getText("SlcCrtFreetime")}
                            value={this.props.criteria.showFreeTime}
                            onChange={(v) => this.props.updateShowFreeTime(v)} />
                    </div>
                    <div style={{ margin: "20px 0px" }}>
                        <div style={{ ...inputContainerStyle, marginBottom: 20 }}>
                            <Select
                                standard={false}
                                style={{
                                    paddingLeft: 18,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    borderRadius: 6,
                                    width: "100%",
                                    height: 38
                                }}
                                value={this.props.filterModel.ratesType}
                                options={ratesTypeOptions()}
                                onChange={(value: SelectionStore.RatesTypeFilter) => this.props.filterUpdateRatesType(value)} />
                        </div>
                        <div style={{ ...inputContainerStyle }}>
                            <InputText
                                placeholder={"Reference/Commodity/NAC..."}
                                style={{ width: "100%" }}
                                inputProps={{
                                    style: {
                                        height: 38,
                                        padding: "0px 12px",
                                    }
                                }}
                                type="text"
                                value={this.state.ratesTypeName ? this.state.ratesTypeName : ""}
                                onChange={(e) => this.handleRatesTypeNamesChange(e.target.value)} />
                        </div>
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <ExpensionPanel expanded={this.props.filterModel.carriersOpened}
                            onChange={(val) => {
                                this.props.updateFilterCarriersOpened(val);
                            }}
                            head={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                <div style={{ ...titleStyle, flex: 1 }}>
                                    Carriers
                                </div>
                                <div style={{ flex: 1 }}></div>
                                {this.props.filterModel.carriersOpened
                                    ? <Remove style={{ flex: "0 0 auto", color: Colors.baseColors.lightBlue }} />
                                    : <Add style={{ flex: "0 0 auto", color: Colors.baseColors.lightBlue }} />}
                            </div>}>
                            <div style={{ width: "100%" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    marginBottom: 10,
                                }}>
                                    <Button onClick={() => {
                                        this.props.filterSelectAllCarrier(this.carriersSelected.some(x => !x.isSelected));
                                    }} style={{
                                        height: 28, minWidth: 90, fontSize: 13
                                    }}>{this.carriersSelected.some(x => !x.isSelected)
                                        ? "Select all"
                                        : "Unselect all"}</Button>
                                </div>
                                <div>
                                    <div>
                                        {this.carriersSelected
                                            .filter(cs => !this.props.carriers[cs.carrierId].carrierGroupId)
                                            .map(cs =>
                                                <div key={cs.carrierId}>
                                                    <CheckBox
                                                        labelPlacement={"end"}
                                                        size={"small"}
                                                        labelStyle={{ fontSize: 14 }}
                                                        label={this.props.carriers[cs.carrierId].name}
                                                        value={cs.isSelected}
                                                        onChange={(value) => this.props.filterSelectCarrier(cs.carrierId, value)} />
                                                </div>
                                            )}
                                    </div>
                                    {this.carrierGroupsSelected
                                        .map(cgs =>
                                            <div key={cgs.carrierGroupId}>
                                                <ExpansionPanel expanded={cgs.isOpen ? true : false}
                                                    onChange={(val) => {
                                                        this.props.filterToggleCarrierGroup(cgs.carrierGroupId);
                                                    }}
                                                    head={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                                        <div style={{ flex: 1 }}>
                                                            <CheckBox
                                                                labelPlacement={"end"}
                                                                size={"small"}
                                                                labelStyle={{ fontSize: 14 }}
                                                                label={this.props.carrierGroups[cgs.carrierGroupId].name}
                                                                value={cgs.isSelected}
                                                                onChange={(value) => this.props.filterSelectCarrierGroup(cgs.carrierGroupId, value)} />
                                                        </div>
                                                        <div style={{ flex: 1 }}></div>
                                                        {cgs.isOpen
                                                            ? <Remove style={{ flex: "0 0 auto", color: Colors.baseColors.lightBlue }} />
                                                            : <Add style={{ flex: "0 0 auto", color: Colors.baseColors.lightBlue }} />}
                                                    </div>}>
                                                    <div style={{ paddingLeft: 5 }}>
                                                        {cgs.carriers
                                                            .map(cs =>
                                                                <div key={cs.carrierId} style={{ paddingLeft: 25 }}>
                                                                    <CheckBox
                                                                        labelPlacement={"end"}
                                                                        size={"small"}
                                                                        labelStyle={{ fontSize: 14 }}
                                                                        label={this.props.carriers[cs.carrierId].name}
                                                                        value={cs.isSelected}
                                                                        onChange={(value) => this.props.filterSelectCarrier(cs.carrierId, value)} />
                                                                </div>
                                                            )}
                                                    </div>
                                                </ExpansionPanel>
                                            </div>)}
                                </div>
                            </div>
                        </ExpensionPanel>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ ...titleStyle }}>{getText("SlcFilters")}</div>
                        <div>
                            <label style={{ marginRight: 6 }}>{getText("SlcFilterMaxAllIn")}</label>
                            <span style={{ fontWeight: "bold", color: Colors.baseColors.lightBlue }}>
                                {Math.ceil(this.props.filterModel.maxAllIn / this.props.currencies[this.props.currencyId].value) +
                                    ' ' + this.props.currencies[this.props.currencyId].code}
                            </span>
                        </div>
                        <div>
                            <Slider
                                minValue={this.props.filterOptions.minAllIn}
                                maxValue={this.props.filterOptions.maxAllIn}
                                value={this.props.filterModel.maxAllIn}
                                valueRender={(value: number) => value.toString()}
                                onChange={(value: number) => this.props.filterUpdateMaxAllIn(value)} />
                        </div>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <div>
                            <label style={{ marginRight: 6 }}>{getText("SlcFilterMaxTransitTime")}</label>
                            <span style={{ fontWeight: "bold", color: Colors.baseColors.lightBlue }}>{this.props.filterModel.maxTransitTime}</span>
                        </div>
                        <div>
                            <Slider
                                minValue={this.props.filterOptions.minTransitTime}
                                maxValue={this.props.filterOptions.maxTransitTime}
                                value={this.props.filterModel.maxTransitTime}
                                valueRender={(value: number) => value.toString()}
                                onChange={(value: number) => this.props.filterUpdateMaxTransitTime(value)} />
                        </div>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <div>
                            <label style={{ marginRight: 6 }}>{getText("SlcFilterMaxTransShipment")}</label>
                            <span style={{ fontWeight: "bold", color: Colors.baseColors.lightBlue }}>{this.props.filterModel.maxTransShipment}</span>
                        </div>
                        <div>
                            <Slider
                                minValue={0}
                                maxValue={this.props.filterOptions.maxTransShipment}
                                value={this.props.filterModel.maxTransShipment}
                                valueRender={(value: number) => value.toString()}
                                onChange={(value: number) => this.props.filterUpdateMaxTransShipment(value)} />
                        </div>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <div>
                            <label style={{ marginRight: 6 }}>{getText("SlcFilterEta")}</label>
                            <span style={{ fontWeight: "bold", color: Colors.baseColors.lightBlue }}>{Moment(this.props.filterModel.maxEtaDate).format('DD/MM/YYYY')}</span>
                        </div>
                        <div>
                            <Slider
                                minValue={this.props.filterOptions.minEtaDate}
                                maxValue={this.props.filterOptions.maxEtaDate}
                                value={this.props.filterModel.maxEtaDate}
                                valueRender={(value: number) => value.toString()}
                                onChange={(value: number) => this.props.filterUpdateEtaDate(value)}
                            />
                        </div>
                    </div>
                </div >
                <div style={{ height: 0, overflow: "visible", textAlign: "center" }}>
                    <Button style={{ height: 32, position: "relative", bottom: 16, zIndex: 100, backgroundColor: "white" }}
                        onClick={(e) => {
                            this.setState({ ratesTypeName: "" });
                            this.props.resetOptions();
                        }}>
                        Reset options
                    </Button>
                </div>
            </div >
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        let filterOptions = selectionConnect.getFilterOptions(state);
        let subscription = getSubscription(state.account.currentUser.clientModel)
            || { subscriptionType: null };
        return {
            criteria: state.criteria.criteria,
            filterModel: state.selection.filterModel,
            currencies: state.seed.currencies,
            carriers: state.seed.carriers,
            carrierGroups: state.seed.carrierGroups,
            currencyId: state.account.currentUser.clientModel.currencyId,
            filterOptions: filterOptions,
            sizeTypes: state.seed.sizeTypes,
            subscription: subscription,
            criteriaLoaded: state.selection.criteriaLoaded,
            carrierOffers: state.selection.selection.carrierOffers || [],
            advContainerOpened: state.selection.advContainerOpened
        } as any
    },
    {
        ...SelectionStore.actionCreators,
        ...CriteriaStore.actionCreators
    })(SelectionFilter) as any as React.ComponentClass<SelectionFilterExternalProps>;