import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from "redux";
import * as Api from "../api/api";
import Moment from "moment";
import * as MultiPortStore from './MultiPort';
import { AppThunkAction } from "./";
import { getDefaultHeaders } from "../utils/utils";
import Notifications from "react-notification-system-redux";
import { getText } from "../utils/langTexts";



export interface UserState {
    isLoading: boolean;
    requestTime?: number;
    entities: Array<Api.UserManagementModel>;
    selectedId?: number;
    selectedFirms: Api.SubscriptionsManagemenet[];
/*    userRegister: Api.UserRegisterModel,*/
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }

}




interface ReceiveUserEntitties { type: "RECEIVE_USER_ENTITIES"; payload: { requestTime: number; entities?: Array<Api.UserManagementModel> }; error?: any;}
interface RequestUserEntitties { type: "REQUEST_USER_ENTITIES"; payload: { requestTime: number }; }


interface RequestUserDeactivate {   type: "REQUEST_USER_DEACTIVATE";    payload: { requestTime: number; id: number; };}
interface ReceiveUserDeactivate { type: "RECEIVE_USER_DEACTIVATE"; payload: { requestTime: number; id: number; entity?: Api.UserManagementModel }; error?: any; }

interface RequestCarrierRatesSettingsCreate {
    type: "REQUEST_USER_CREATE";
    payload: { requestTime: number };
}
interface ReceiveCarrierRatesSettingsCreate {
    type: "RECEIVE_USER_CREATE";
    payload: { requestTime: number; entity?: Api.UserManagementModel };
    error?: any;
}

interface RequestCarrierRatesSettingsUpdate {
    type: "REQUEST_USER_UPDATE";
    payload: { requestTime: number; id: number; };
}
interface ReceiveCarrierRatesSettingsUpdate {
    type: "RECEIVE_USER_UPDATE";
    payload: { requestTime: number; id: number; entity?: Api.UserManagementModel };
    error?: any;
}

interface UpdateFirmSelected {
    type: "UPDATE_FIRM_SELECTED"; payload: { firmId: number; value: boolean }; 
}

interface UpdateUserSelectedId {
    type: "UPDATE_USER_SELECTEDID";
    payload: { value: number; };
}

export type KnownAction = ReceiveUserEntitties | RequestUserEntitties |
    RequestUserDeactivate | ReceiveUserDeactivate | RequestCarrierRatesSettingsCreate | ReceiveCarrierRatesSettingsCreate
    | RequestCarrierRatesSettingsUpdate | ReceiveCarrierRatesSettingsUpdate | UpdateFirmSelected | UpdateUserSelectedId
    ;


export const actionCreators = {

    updateFirmSelected: (firmId: number, value: boolean) => <UpdateFirmSelected>{
        type: 'UPDATE_FIRM_SELECTED', payload: { firmId: firmId, value: value }
    },
    updateUserSelectedId: (id: number) => <UpdateUserSelectedId>{
        type: "UPDATE_USER_SELECTEDID",
        payload: { value: id }
    },
    requestUsers: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entities => {
                dispatch({
                    type: "RECEIVE_USER_ENTITIES",
                    payload: { requestTime: requestTime, entities: entities }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_ENTITIES",
                    payload: { requestTime: requestTime, },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_USER_ENTITIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    requestResetUser: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.disable({ id: id }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id, entity: entity }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_USER_DEACTIVATE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetch;
    },
    requestDeactivateUser: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.disable({ id: id }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id, entity : entity }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_DEACTIVATE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_USER_DEACTIVATE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetch;
    },
    requestCreateUser: (requestTime: number, model: Api.UserManagementModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.create({ model: model }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_USER_CREATE",
                    payload: { requestTime: requestTime, entity: entity }
                });
                dispatch(Notifications.success({ message:"User created!", title: getText("GenSuccess"), position: "tc" }) as any);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_CREATE",
                    payload: { requestTime: requestTime, },
                    error: err
                });
                dispatch(Notifications.error({ message: "User creation failure", title: getText("GenError"), position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_USER_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestUpdateUser: (requestTime: number, model: Api.UserManagementModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.UsersApi();
        let fetch = api.update({ model: model }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_USER_UPDATE",
                    payload: { requestTime: requestTime, id: model.clientId, entity: entity }
                });
                dispatch(Notifications.success({ message: "User updated!", title: getText("GenSuccess"), position: "tc" }) as any);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_USER_UPDATE",
                    payload: { requestTime: requestTime, id: model.clientId },
                    error: err
                });
                dispatch(Notifications.error({ message: "User update failure", title: getText("GenError"), position: "tc" }) as any);
            });

        dispatch({
            type: "REQUEST_USER_UPDATE",
            payload: { requestTime: requestTime, id: model.clientId }
        });
        return fetch;
    },
}

const unloadedState: UserState = {
    isLoading: false,
    entities: [],
    selectedFirms: [],
    updateState: {},
    createState: {
        isLoading: false
    },
};


export const reducer: Reducer<UserState> = (state: UserState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    
  

    switch (action.type) {
        case "REQUEST_USER_ENTITIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_USER_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                entities: action.error
                    ? state.entities
                    : action.payload.entities
            };

        case "REQUEST_USER_CREATE":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_USER_CREATE":
            if (action.payload.requestTime !== state.createState.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                },
                entities: action.error
                    ? state.entities
                    : state.entities.concat([action.payload.entity])
            };
        case "REQUEST_USER_UPDATE":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_USER_UPDATE":
            if (!state.updateState[action.payload.id]
                || action.payload.requestTime !== state.updateState[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                },
                entities: action.error
                    ? state.entities
                    : state.entities.map(x => x.clientId === action.payload.entity.clientId
                        ? { ...x, ...action.payload.entity }
                        : x)
            };
        case "REQUEST_USER_DEACTIVATE":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_USER_DEACTIVATE":       
            if (!state.updateState[action.payload.id]
                || action.payload.requestTime !== state.updateState[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    [action.payload.id]: {
                        ...state.updateState[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                },
                entities: action.error
                    ? state.entities
                    : state.entities.map(x => x.clientId === action.payload.entity.clientId
                        ? {
                            ...x,
                            account: {
                             ...x.account,
                             expirationDate: action.payload.entity.account.expirationDate
                            }
                        }
                        : x),
            };
        case "UPDATE_FIRM_SELECTED":
            var firm = state.selectedFirms.map(x => x.firmId === action.payload.firmId);

            return {
                ...state,
                selectedFirms: state.selectedFirms.map(x => x.firmId === action.payload.firmId
                    ? {
                        ...x,
                        isSelected: action.payload.value
                    }
                    : x),
            };  
        case "UPDATE_USER_SELECTEDID":            
            return {
                ...state,
                selectedId: action.payload.value
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
