import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import Moment from 'moment';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as FormField from './FormField';
import CriteriaSizeType from './CriteriaSizeType';
import { getSubscription } from '../utils/utils';
import { getText } from '../utils/langTexts';
import ChargeCriteriaInput from "./ChargeCriteriaInput";
import CriteriaLocationSelect from "./CriteriaLocationSelect";
import { canUseSuggested, canUseCarrierRates } from '../security/userRights';
import { incotermOptions } from './QuotationInformationForm';
import * as _ from 'lodash';
import * as Colors from '../styles/colors';
import { CSSProperties } from 'react';
import Button from './Button';
import { Add, Remove } from '@material-ui/icons';
import ExpensionPanel from "./ExpansionPanel";
import { isSmallScreen } from '../utils/screenUtils';
import WeightPicker from './WeightPicker';
import * as CriteriaStore from '../store/Criteria';

type CriteriaFormProps = ReduxForm.InjectedFormProps<Api.CriteriaModel, {}, string>
    & CriteriaFormOwnProps
    & CriteriaFormExternalProps
    & typeof CriteriaStore.actionCreators;;

export const containerTypeOptions = (): Array<{ label: string; value: Api.CriteriaModelContainerTypeEnum }> => [
    { label: getText("GenCtrDry"), value: "Dry" },
    { label: getText("GenCtrRefeer"), value: "Rf" },
    { label: getText("GenCtrFlat"), value: "Fl" },
    { label: getText("GenCtrOpenTop"), value: "Ot" },
    { label: getText("GenCtrTankTop"), value: "Tk" }
];

interface CriteriaFormOwnProps {
    criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel>;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    containerType: Api.CriteriaModelContainerTypeEnum;
    subscription: Api.SubscriptionModel;
    subscriptions: Array<Api.SubscriptionModel>;
    dateBegin: Date;
    dateEnd: Date;
    priceByContainer: boolean;
    shipperName: string;
    consigneeName: string;
    isSpotOperation: boolean;
}

interface CriteriaFormExternalProps {
    onSubmit: (values: Api.CriteriaModel) => void;
    resetCriteria: () => void;
    toggleAdvContainer: (value: boolean) => void;
    toggleAdvLocation: (value: boolean) => void;
    toggleAdvDate: (value: boolean) => void;
    advContainerOpened: boolean;
    advLocationOpened: boolean;
    advDateOpened: boolean;
    style?: React.CSSProperties;
}

const containerRequired = (value: Array<Api.CriteriaSizeTypeModel>) => (!value || !value.some(x => x.number > 0)) && getText("CrtContainerRequired");
const locationRequired = (value: Api.LocationModel) => (!value || !value.locationId) && getText("GenRequired");
const consigneeOrShipper = (value, allValues: Api.CriteriaModel) => (allValues.criteriaAgent
    && !allValues.criteriaAgent.consigneeName
    && !allValues.criteriaAgent.shipperName && getText("GenOneRequired"));
const commentField = FormField.textAreaField(4, 40);
const cargoDescriptionField = FormField.textAreaField(4, 40);
const additionalLabelStyle: React.CSSProperties = { fontSize: 18, fontWeight: 500 };

const getInputSize = (): { height: number, width: number } => {
    if (isSmallScreen()) {
        return {
            height: 35, width: 35
        };

    } else {
        return {
            height: 42, width: 42
        };
    }
}

const originField = (fields) => {
    let inputSize = getInputSize();
    return <div>
        <CriteriaLocationSelect
            inputKey={"CrtOrigin"}
            buttonStyle={{ height: inputSize.height, width: inputSize.width }}
            inputStyle={{ height: inputSize.height }}
            value={fields.input.value}
            locationSearchType={"Normal"}
            onChange={(value) => fields.input.onChange(value)} />
        <FormField.FieldErrors {...fields.meta} />
    </div>;
}

const destinationField = (fields) => {
    let inputSize = getInputSize();
    return <div>
        <CriteriaLocationSelect
            inputKey={"CrtDestination"}
            buttonStyle={{ height: inputSize.height, width: inputSize.width }}
            inputStyle={{ height: inputSize.height }}
            value={fields.input.value}
            locationSearchType={"Normal"}
            onChange={(value) => fields.input.onChange(value)} />
        <FormField.FieldErrors {...fields.meta} />
    </div>;
}

const containerTypeSelect = FormField.getSelectField(containerTypeOptions());
const formPartStyle: CSSProperties = {
    flex: "0 0 auto"
};
const formPartBigStyle: CSSProperties = {
    ...formPartStyle,
};
const formPartSmallStyle: CSSProperties = {
    ...formPartStyle,
};
const advBtnContainer: CSSProperties = {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "10px 0px",
    width: "100%"
}
const getAdvBtn = (): CSSProperties => {
    if (isSmallScreen()) {
        return {
            fontWeight: 700,
            width: 120,
            height: 28
        };
    } else {
        return {
            fontWeight: 700,
            width: 120,
            height: 35
        };
    }
}

const advIconStyle: CSSProperties = {
    fontSize: 14,
    fontWeight: "bold"
}

const titleStyle: React.CSSProperties = {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20
};

class CriteriaForm extends React.Component<CriteriaFormProps, {}> {
    public render() {
        let inputSize = getInputSize();

        var allowedSizes = _.values(this.props.sizeTypes).filter(x => x.type === this.props.containerType).map(x=>x.sizeTypeId);
        var criteriaList = this.props.criteriaSizeTypes.filter(c => allowedSizes.includes(c.sizeTypeId));
        var additonalCriteria = _.sortBy(criteriaList, x => this.props.sizeTypes[x.sizeTypeId].position).slice(3);
        var mainCriteria = _.sortBy(criteriaList, x => this.props.sizeTypes[x.sizeTypeId].position).slice(0, 3);

        return (
            <form onSubmit={this.props.handleSubmit}
                style={{
                    width: 650,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    ...this.props.style
                }}>
                <div style={{ ...formPartBigStyle }} id={"criteria-1"}>
                    <div style={{ marginBottom: 5, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                        <div style={{ ...titleStyle }}>{getText("CrtContainers")}</div>
                        {(!this.props.subscription || this.props.subscription.subscriptionType !== "Agent")
                            && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 15 }}>
                                <div style={{ flex: "0 0 auto", minWidth: 150, marginRight: 12 }}>
                                    <div style={{ ...additionalLabelStyle }}>{getText("CrtContainerType")}:</div>
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    <ReduxForm.Field name={"containerType"}
                                        validate={FormField.required}
                                        onChange={(value) => this.props.selectContainerType(value)}
                                        component={FormField.getSelectField(containerTypeOptions())} />
                                </div>
                            </div>}
                        {mainCriteria.length > 0
                            && <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "top"
                            }}>
                            {mainCriteria.map(st => <div style={{ flex: 1, textAlign: "center" }}>
                                {this.props.sizeTypes[st.sizeTypeId].name}
                            </div>)}
                        </div>}
                        {(mainCriteria.length > 0 ?
                                    <ReduxForm.FieldArray
                                        name="criteriaSizeTypes"
                                        component={CriteriaSizeTypes}
                                        validate={[containerRequired]}
                                        props={{
                                            sizeTypes: criteriaList,
                                            sizeTypeIdFilter: mainCriteria.map(x => x.sizeTypeId),
                                            criteriaSizeTypes: _.sortBy(this.props.criteriaSizeTypes.filter(x => x.sizeTypeId), x => this.props.sizeTypes[x.sizeTypeId].position),
                                            containerType: this.props.containerType,
                                            onChange: (value) => this.props.updateContainerCriteria(value),
                                            min: 0,
                                            max: this.props.priceByContainer
                                                ? 1 : 9
                                        } as CriteriaSizeTypesOwnProps} />
                                     : <div style={{ flex: 1 }}></div>)}
                    </div>
                    <ExpensionPanel expanded={this.props.advContainerOpened}
                        onChange={(value) => this.props.toggleAdvContainer(value)}
                        head={<div style={{ ...advBtnContainer }}>
                            <Button
                                type="button"
                                style={{ ...getAdvBtn() }}
                                onClick={(e) => {
                                    this.props.toggleAdvContainer(!this.props.advContainerOpened);
                                    if (e.preventDefault) {
                                        e.preventDefault();
                                    }
                                }}>
                                {getText("CrtOptions")} {this.props.advContainerOpened
                                    ? <Remove style={{ ...advIconStyle }} />
                                    : <Add style={{ ...advIconStyle }} />}
                            </Button>
                        </div>}>
                        <div style={{  display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: 10, alignItems: "top",  width:"100%" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {additonalCriteria.length > 0
                                    && <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "top"
                                    }}>
                                        {additonalCriteria.map(st => <div style={{ flex: 1, textAlign: "center" }}>
                                            {this.props.sizeTypes[st.sizeTypeId].name}
                                        </div>)}
                                    </div>}
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                                        {(additonalCriteria.length > 0 ?
                                            <ReduxForm.FieldArray
                                                name="criteriaSizeTypes"
                                                component={CriteriaSizeTypes}
                                                validate={[containerRequired]}
                                                props={{
                                                    sizeTypes: criteriaList,
                                                    sizeTypeIdFilter: additonalCriteria.map(x => x.sizeTypeId),
                                                    criteriaSizeTypes: _.sortBy(this.props.criteriaSizeTypes.filter(x => x.sizeTypeId), x => this.props.sizeTypes[x.sizeTypeId].position),
                                                    containerType: this.props.containerType,
                                                    onChange: (value) => this.props.updateContainerCriteria(value),
                                                    min: 0,
                                                    max: this.props.priceByContainer
                                                        ? 1 : 9
                                                } as CriteriaSizeTypesOwnProps} />
                                            : <div style={{ flex: 1 }}></div>)}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", paddingTop: 10 }}>
                                        <ReduxForm.Field name={"containerPrice"}
                                            validate={[]}
                                            labelStyle={{ fontSize: 14 }}
                                            component={FormField.checkBoxField(getText("CrtPriceByContainer"))} />
                                    </div>
                                </div>
                            <div style={{ flex: 1 }}>
                                {(!this.props.subscription || this.props.subscription.subscriptionType !== "Agent")
                                    &&
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                                        <div style={{ flex: "0 0 auto", minWidth: 150 }}>
                                            <div style={{ ...additionalLabelStyle }}>{getText("CrtSurchargesType")}</div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <ReduxForm.Field name="chargeCriterias"
                                                component={(fields) => <div>
                                                    <div>
                                                        <ChargeCriteriaInput
                                                            label={getText("GenHazardous")}
                                                            labelStyle={{ minWidth: 90 }}
                                                            chargeCriteria={"Hazardous"}
                                                            value={fields.input.value}
                                                            onChange={(value) => fields.input.onChange(value)} />
                                                    </div>
                                                    <div>
                                                        <ChargeCriteriaInput
                                                            label={getText("GenNor")}
                                                            labelStyle={{ minWidth: 90 }}
                                                            chargeCriteria={"Nor"}
                                                            value={fields.input.value}
                                                            onChange={(value) => fields.input.onChange(value)} />
                                                    </div>
                                                    <div>
                                                        <ChargeCriteriaInput
                                                            label={getText("GenContainerOwned")}
                                                            labelStyle={{ minWidth: 90 }}
                                                            chargeCriteria={"Owned"}
                                                            value={fields.input.value}
                                                            onChange={(value) => fields.input.onChange(value)} />
                                                    </div>
                                                    <div>
                                                        <ChargeCriteriaInput
                                                            label={getText("GenGoh")}
                                                            labelStyle={{ minWidth: 90 }}
                                                            chargeCriteria={"Goh"}
                                                            value={fields.input.value}
                                                            onChange={(value) => fields.input.onChange(value)} />
                                                    </div>
                                                    <div>
                                                        <ChargeCriteriaInput
                                                            label={getText("GenGohDouble")}
                                                            labelStyle={{ minWidth: 90 }}
                                                            chargeCriteria={"GohDouble"}
                                                            value={fields.input.value}
                                                            onChange={(value) => fields.input.onChange(value)} />
                                                    </div>
                                                </div>} />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </ExpensionPanel>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ ...formPartStyle }} id={"criteria-2"}>
                    <div style={{ marginBottom: 5 }}>
                        <div style={{ ...titleStyle }}>{getText("CrtPorts")}</div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: 1, paddingRight: 30 }}>
                                <div style={{ paddingLeft: 4 }}>
                                    <div>{getText("CrtOrigin")}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: 5 }}>
                                    <ReduxForm.Field name={"origin"}
                                        validate={[locationRequired]}
                                        component={originField} />
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", columnGap: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <ReduxForm.Field
                                                style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                                validate={[]}
                                                name="originShippingType"
                                                component={FormField.renderField}
                                                type="radio"
                                                value="Cy" />
                                            <div>CY</div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <ReduxForm.Field
                                                style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                                validate={[]}
                                                name="originShippingType"
                                                component={FormField.renderField}
                                                type="radio"
                                                value="Door" />
                                            <div>Door</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1, paddingRight: 30 }}>
                                <div style={{ paddingLeft: 4 }}>
                                    <div>{getText("CrtDestination")}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: 5 }}>
                                    <ReduxForm.Field name={"destination"}
                                        validate={[locationRequired]}
                                        component={destinationField} />
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", columnGap: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <ReduxForm.Field
                                                style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                                validate={[]}
                                                name="destinationShippingType"
                                                component={FormField.renderField}
                                                type="radio"
                                                value="Cy" />
                                            <div>CY</div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <ReduxForm.Field
                                                style={{ width: 16, height: 16, accentColor: '#00A1C4' }}
                                                validate={[]}
                                                name="destinationShippingType"
                                                component={FormField.renderField}
                                                type="radio"
                                                value="Door" />
                                            <div>Door</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(!this.props.subscription
                    || this.props.subscription.subscriptionType !== "Agent")
                    && <div style={{ flex: 1 }}></div>}
                {(!this.props.subscription
                    || this.props.subscription.subscriptionType !== "Agent")
                    && <div style={{ ...formPartStyle, marginTop: 10 }} id={"criteria-3"}>
                        <div style={{ ...titleStyle }}>{getText("CrtAllInIncluding")}</div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <ReduxForm.Field name={"loadingCharge"}
                                        validate={[]}
                                        component={FormField.checkBoxField(getText("CrtLoadingCharges"))} />

                                </div>
                                {/*<div>*/}
                                {/*    <ReduxForm.Field name={"prepaidCharge"}*/}
                                {/*        validate={[]}*/}
                                {/*        component={FormField.checkBoxField(getText("CrtPrepaidCharges"))} />*/}
                                {/*</div>*/}

                            </div>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <ReduxForm.Field name={"unLoadingCharge"}
                                        validate={[]}
                                        component={FormField.checkBoxField(getText("CrtUnLoadingCharges"))} />
                                </div>
                                {/*<div>*/}
                                {/*    <ReduxForm.Field name={"collectCharge"}*/}
                                {/*        validate={[]}*/}
                                {/*        component={FormField.checkBoxField(getText("CrtCollectCharges"))} />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>}
                <div style={{ flex: 1 }}></div>
                <div style={formPartStyle} id={"criteria-4"}>
                    <div style={{ marginBottom: 5 }}>
                        <div style={{ ...titleStyle, marginTop: 15 }}>{getText("CrtDates")}</div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ paddingLeft: 4 }}>{getText("CrtFromDate")}</div>
                                <div>
                                    <ReduxForm.Field name={"dateBegin"}
                                        component={(fields) => <FormField.DatePickerField {...fields}
                                            style={{ height: inputSize.height - 2 }}
                                            inputContainerStyle={{}}
                                            iconContainerStyle={{
                                                height: inputSize.height,
                                                width: inputSize.height,
                                                minHeight: inputSize.height,
                                                fontSize: inputSize.height - 5
                                            }}
                                            maxDate={this.props.dateEnd} />} />
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ paddingLeft: 4 }}>{getText("CrtToDate")}</div>
                                <div>
                                    <ReduxForm.Field name={"dateEnd"}
                                        component={(fields) => <FormField.DatePickerField {...fields}
                                            style={{ height: inputSize.height - 2 }}
                                            inputContainerStyle={{}}
                                            iconContainerStyle={{
                                                height: inputSize.height,
                                                width: inputSize.height,
                                                minHeight: inputSize.height,
                                                fontSize: inputSize.height - 5
                                            }}
                                            maxDate={Moment(this.props.dateBegin).add(93, "days").toDate()}
                                            minDate={this.props.dateBegin} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {(!this.props.subscription || this.props.subscription.subscriptionType !== "Agent")
                        && <ExpensionPanel expanded={this.props.advDateOpened}
                            onChange={(value) => this.props.toggleAdvDate(value)}
                            head={<div style={{ ...advBtnContainer }}>
                                <Button
                                    type="button"
                                    style={{ ...getAdvBtn() }}>
                                    {getText("CrtOptions")} {this.props.advDateOpened
                                        ? <Remove style={{ ...advIconStyle }} />
                                        : <Add style={{ ...advIconStyle }} />}
                                </Button>
                            </div>}>
                            <div style={{
                                padding: "10px 0px", display: "flex", flexDirection: "row",
                                alignItems: "flex-start", rowGap: 10, columnGap: 40,
                                flexWrap: "wrap"
                            }}>
                                <div style={{ flex: "0 0 auto" }}>
                                    <div>
                                        <ReduxForm.Field name={"lastRates"}
                                            validate={[]}
                                            labelStyle={{ fontSize: 14, minWidth: 170 }}
                                            component={FormField.checkBoxField(getText("CrtIncludeLastRates"))} />
                                    </div>
                                    {this.props.subscriptions.some(x => x.subscriptionType === "Okargo"
                                        && x.agency.agencyMarkets && x.agency.agencyMarkets.length > 0)
                                        && < div >
                                            <ReduxForm.Field name={"allMarkets"}
                                                validate={[]}
                                                labelStyle={{ fontSize: 14, minWidth: 170 }}
                                                component={FormField.checkBoxField(getText("CrtAllMarkets"))} />
                                        </div>}
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    {canUseSuggested(this.props.subscription)
                                        && <div>
                                            <ReduxForm.Field name={"suggested"}
                                                validate={[]}
                                                labelStyle={{ fontSize: 14, minWidth: 170 }}
                                                component={FormField.checkBoxField(getText("CrtIncludeSuggested"))} />
                                        </div>}
                                    {canUseCarrierRates(this.props.subscription)
                                        && <div>
                                            <ReduxForm.Field name={"includeCarrierRates"}
                                                validate={[]}
                                                labelStyle={{ fontSize: 14, minWidth: 170 }}
                                                component={FormField.checkBoxField(getText("CrtIncludeCarrierRates"))} />
                                        </div>}
                                </div>
                            </div>
                        </ExpensionPanel>}
                </div>
                {(this.props.subscription && this.props.subscription.subscriptionType === "Agent") && <div style={{ flex: 1 }}></div>}
                {(this.props.subscription && this.props.subscription.subscriptionType === "Agent") &&
                    <div style={{ ...formPartStyle }}>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentShipper")}</div>
                                        <div>
                                            <ReduxForm.Field
                                                validate={[consigneeOrShipper]}
                                                name="criteriaAgent.shipperName"
                                                disabled={this.props.consigneeName ? true : false}
                                                component={FormField.renderField}
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentConsignee")}</div>
                                        <div>
                                            <ReduxForm.Field
                                                validate={[consigneeOrShipper]}
                                                name="criteriaAgent.consigneeName"
                                                disabled={this.props.shipperName ? true : false}
                                                component={FormField.renderField}
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentOperation")}</div>
                                <div>
                                    <ReduxForm.Field
                                        style={{ width: 20, height: 20 }}
                                        validate={[]}
                                        name="criteriaAgent.isSpotOperation"
                                        component={FormField.renderField}
                                        type="radio"
                                        value="true" />
                                    <div style={{ display: "inline-block", verticalAlign: "super", marginLeft: 3 }}>
                                        {" " + getText("CrtAgentSpot")}
                                    </div>
                                </div>
                                <div>
                                    <ReduxForm.Field
                                        style={{ width: 20, height: 20 }}
                                        validate={[]}
                                        name="criteriaAgent.isSpotOperation"
                                        component={FormField.renderField}
                                        type="radio"
                                        value="false" />
                                    <div style={{ display: "inline-block", verticalAlign: "super", marginLeft: 3 }}>
                                        {" " + getText("CrtAgentRegular")}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentVolume")}</div>
                                <div>
                                    <ReduxForm.Field
                                        validate={[FormField.required]}
                                        name="criteriaAgent.volume"
                                        component={FormField.renderField}
                                        type="text" />
                                </div>
                                <div style={{ textAlign: "left", marginLeft: 3 }}>

                                </div>
                            </div>
                            <div>
                                <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentIncoterm")}</div>
                                <div>
                                    <ReduxForm.Field
                                        validate={[FormField.required]}
                                        name="criteriaAgent.incoterm"
                                        component={FormField.getSelectField(incotermOptions())} />
                                </div>
                            </div>
                            <div>
                                <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentCargoDescription")}</div>
                                <div>
                                    <ReduxForm.Field
                                        validate={[FormField.required]}
                                        name="criteriaAgent.CargoDescription"
                                        style={{ maxWidth: "100%" }}
                                        component={cargoDescriptionField} />
                                </div>
                            </div>
                            <div>
                                <div style={{ ...additionalLabelStyle }}>{getText("CrtAgentComment")}</div>
                                <div>
                                    <ReduxForm.Field
                                        validate={[]}
                                        name="criteriaAgent.comment"
                                        style={{ maxWidth: "100%" }}
                                        component={commentField} />
                                </div>
                            </div>
                        </div>
                    </div>}
                <div style={{ flex: 2 }}></div>
                <div style={{ ...formPartStyle, marginTop: 20 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                style={{
                                    width: 135,
                                    height: 45,
                                }}
                                onClick={(e) => {
                                    this.props.reset();
                                }}>
                                {getText("CrtReset")}
                            </Button>
                        </div>
                        <div style={{ flex: 1 }}>
                        </div>
                        <div style={{ flex: "0 0 auto" }} id={"criteria-5"}>
                            <Button
                                type={"submit"}
                                style={{
                                    width: 165,
                                    height: 45,
                                    color: "white",
                                    backgroundColor: Colors.baseColors.lightBlue
                                }}
                                disabled={this.props.submitting}>
                                {getText("CrtGo")}
                            </Button>
                            <div className="text-danger" style={{ textAlign: "center" }}>
                                {!this.props.valid && this.props.anyTouched && <span>{getText("CrtGoValidationError")}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

type CriteriaSizeTypesProps = CriteriaSizeTypesOwnProps
    & ReduxForm.WrappedFieldArrayProps;

export interface CriteriaSizeTypesOwnProps {
    criteriaSizeTypes: Array<Api.CriteriaSizeTypeModel>;
    containerType: Api.CriteriaModelContainerTypeEnum;
    sizeTypeIdFilter: Array<number>;
    sizeTypes: Api.SizeTypeModel[];
    min: number;
    max: number;
    onChange: (value: Api.CriteriaSizeTypeModel) => void;
}

const weightPickerFieldBuilder = (fields) => <WeightPicker
    criteriaSize={fields.input.value}
    iconStyle={{ fontSize: 16 }}
    showLabel={false}
    onChange={(value) => {
        fields.input.onChange({
            ...fields.input.value,
            weight: value.weight,
            weightUnit: value.weightUnit
        })
    }} />

class CriteriaSizeTypes extends React.Component<CriteriaSizeTypesProps, {}> {

    componentWillReceiveProps(nextProps: CriteriaSizeTypesProps) {
        if (this.props.containerType !== nextProps.containerType
        || this.props.sizeTypes.length !== nextProps.sizeTypes.length) {
            nextProps.fields.removeAll();
            nextProps.sizeTypes.filter((st, si) => nextProps.criteriaSizeTypes[si])
                .forEach((st, si) => {
                    nextProps.fields.insert(si, {
                        sizeTypeId: st.sizeTypeId,
                        number: nextProps.criteriaSizeTypes[si].number || 0,
                        weight: nextProps.criteriaSizeTypes[si].weight || 15,
                        weightUnit: nextProps.criteriaSizeTypes[si].weightUnit || "Kg"
                    });
                });
        }
    }

    public render() {
        return <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "top" }}>
                {this.props.sizeTypes
                    .filter(x => this.props.sizeTypeIdFilter.some(y => y === x.sizeTypeId))
                    .map((st) => {
                        let index = this.props.sizeTypes.indexOf(st);
                        return <div key={index} style={{flex: 1}}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <ReduxForm.Field name={`criteriaSizeTypes[${index}].number`}
                                                 component={(fields) => <CriteriaSizeType
                                                     sizeTypes={this.props.sizeTypes}
                                                     sizeTypeId={st.sizeTypeId}
                                                     min={this.props.min}
                                                     max={this.props.max}
                                                     iconStyle={{fontSize: 16}}
                                                     value={fields.input.value || 0}
                                                     onChange={(value) => fields.input.onChange(value)}/>}/>
                                <ReduxForm.Field name={`criteriaSizeTypes[${index}].sizeTypeId`}
                                                 component={(fields) => <input
                                                     type="hidden"
                                                     value={fields.input.value}/>}/>
                                {this.props.criteriaSizeTypes[index]?.number > 0 &&
                                    <ReduxForm.Field name={`criteriaSizeTypes[${index}]`}
                                                     component={weightPickerFieldBuilder}/>}
                            </div>
                        </div>;
                    })}
            </div>
            <FormField.FieldErrors {...this.props.meta} />
        </div>
    }
}

let criteriaFormWrapped = ReduxForm.reduxForm({
    form: 'criteriaFcl',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(CriteriaForm) as any;
const selector = ReduxForm.formValueSelector('criteriaFcl')

export default connect((state: ApplicationState) => {
        return {
            initialValues: {
                ...state.criteria.criteria,
                type: "Fcl"
            } as Api.CriteriaModel,
            criteriaSizeTypes: selector(state, "criteriaSizeTypes") || [],
            containerType: selector(state, "containerType"),
            sizeTypes: state.seed.sizeTypes,
            subscription: getSubscription(state.account.currentUser.clientModel),
            subscriptions: state.account.currentUser.clientModel.subscriptions,
            dateBegin: selector(state, "dateBegin"),
            dateEnd: selector(state, "dateEnd"),
            priceByContainer: selector(state, "priceByContainer"),
            consigneeName: selector(state, 'criteriaAgent.consigneeName'),
            isSpotOperation: selector(state, 'criteriaAgent.isSpotOperation'),
            shipperName: selector(state, 'criteriaAgent.shipperName'),
        } as CriteriaFormOwnProps
    },
    {
        ...CriteriaStore.actionCreators
    })(criteriaFormWrapped) as any as React.ComponentClass<CriteriaFormExternalProps>