import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import { ApplicationState } from '../store';
import * as Colors from "../styles/colors";
import * as DocumentStore from "../store/Document";
import { getText } from '../utils/langTexts';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import CenteredContentTabs from './CenteredContentTabs';
import { getFirmName } from '../utils/utils';

type DocumentProps = DocumentOwnProps
    & DocumentStore.DocumentState
    & typeof DocumentStore.actionCreators;

interface DocumentOwnProps {
    carriers: { [id: number]: Api.CarrierModel };
    selectedTab: string
}

const containerStyle = {
};

const elementStyle = {
    cursor: "pointer",
    hover: "yellow",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "12px",
    padding: "40px",
    
} as React.CSSProperties;

const textElementStyle = {
    textAlign: "center",
    width: "150px",
} as React.CSSProperties;


const folderUnclickedStyleOkargo = {
    height: "100%",
    flex: 1,
    borderColor: Colors.baseColors.darkGray,
    backgroundColor: "white",
    borderRadius: "0px 15px 0px 0px",
    border: `2px solid ${Colors.baseColors.darkGray}`,
    borderBottom: `2px solid ${Colors.baseColors.lightBlue}`,
    borderLeftWidth: `2px solid ${Colors.baseColors.darkGray}`,
    paddingBottom: "2px",

} as React.CSSProperties;

const folderUnclickedStyle = {
    ...folderUnclickedStyleOkargo,
    borderLeft: "none",
    borderWidth: "2px",
    paddingBottom: "2px",
    borderBottomStyle: "inset",
    borderRightColor: Colors.baseColors.darkGray,

} as React.CSSProperties;

const docStyle = {
    borderLeft: `2px solid ${Colors.baseColors.lightBlue}`,
    borderBottom: `2px solid ${Colors.baseColors.lightBlue}`,
    borderRight: `2px solid ${Colors.baseColors.lightBlue}`,
    borderRadius: "0px 0px 5px 5px",
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    flexWrap: "wrap",
    //marginTop: "-4px",
    minHeight: 350
} as React.CSSProperties;

const iconStyle = {
    cursor: "pointer",
} as React.CSSProperties;

class Document extends React.Component<DocumentProps, {}> {

    onPropsUpdate(props: DocumentProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestFirmDocuments(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: DocumentProps) {
        this.onPropsUpdate(nextProps);
    }
    public render() {
        return (
            <div style={containerStyle}>
                <h3>My Documents</h3>
                <div style={{
                    marginRight: "12px",
                    borderTop: `2px solid ${Colors.baseColors.darkGray}`,
                    position: "relative",
                    top: "2px",
                }} />
                <div>
                    <CenteredContentTabs
                        tabKey={tabName}
                        data={[
                            {
                                title: getText("DocOkargoDocuments"), //<FolderIcon style={iconStyle} />
                                titleIcon: <FolderIcon style={iconStyle} />,
                                tabKey: "okargo",
                                tabContent: <div style={docStyle}>
                                    <div className="documentHover" style={elementStyle} onClick={(e) => {
                                        if (!this.props.downloadStates["tos"]
                                            || !this.props.downloadStates["tos"].isLoading) {
                                            this.props.downloadTos(new Date().getTime(), "en");
                                        }
                                    }}>
                                        <DescriptionIcon style={iconStyle} />
                                        <div style={textElementStyle}>
                                            <i></i> Okargo General Terms and Conditions of Sale (EN)
                                        </div>
                                    </div>

                                    <div className="documentHover" style={elementStyle} onClick={(e) => {
                                        if (!this.props.downloadStates["tos"]
                                            || !this.props.downloadStates["tos"].isLoading) {
                                            this.props.downloadTos(new Date().getTime(), "fr");
                                        }

                                    }}>
                                        <DescriptionIcon style={iconStyle} />
                                        <div style={textElementStyle}>
                                            <i></i> Conditions Générales de Vente (FR)
                                        </div>
                                    </div>

                                    {/*<div className="documentHover" style={elementStyle} onClick={(e) => {*/}
                                    {/*    if (!this.props.downloadStates["quickStart"]*/}
                                    {/*        || !this.props.downloadStates["quickStart"].isLoading) {*/}
                                    {/*        this.props.downloadQuickstart(new Date().getTime(), "en");*/}
                                    {/*    }*/}
                                    {/*}}>*/}
                                    {/*    <DescriptionIcon style={iconStyle} />*/}
                                    {/*    <div style={textElementStyle}>*/}
                                    {/*        <i ></i> Quickstart guide (EN)*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="documentHover" style={elementStyle} onClick={(e) => {*/}
                                    {/*    if (!this.props.downloadStates["quickStart"]*/}
                                    {/*        || !this.props.downloadStates["quickStart"].isLoading) {*/}
                                    {/*        this.props.downloadQuickstart(new Date().getTime(), "fr");*/}
                                    {/*    }*/}
                                    
                                    {/*}}>*/}
                                    {/*    <DescriptionIcon style={iconStyle} />*/}
                                    {/*    <div style={textElementStyle}>*/}
                                    {/*        <i></i> Guide de démarrage rapide (FR)*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="documentHover" style={elementStyle} onClick={(e) => {
                                        if (!this.props.downloadStates["app"]
                                            || !this.props.downloadStates["app"].isLoading) {
                                            this.props.downloadApp(new Date().getTime());
                                        }

                                    }}>
                                        <DescriptionIcon style={iconStyle} />
                                        <div style={textElementStyle}>
                                            <i></i> {getText("DocDownloadApp")}
                                        </div>
                                    </div>

                                    {/*<div className="documentHover" style={elementStyle} onClick={(e) => {*/}
                                    {/*    if (!this.props.downloadStates["guideCrawlers"]*/}
                                    {/*        || !this.props.downloadStates["guideCrawlers"].isLoading) {*/}
                                    {/*        this.props.downloadGuideCrawlers(new Date().getTime(), "en");*/}
                                    {/*    }*/}
                                    
                                    {/*}}>*/}
                                    {/*    <DescriptionIcon style={iconStyle} />*/}
                                    {/*    <div style={textElementStyle}>*/}
                                    {/*        <i></i> SETTING UP CONNECTIONS VIA WEBCRAWLERS*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="documentHover" style={elementStyle} onClick={(e) => {*/}
                                    {/*    if (!this.props.downloadStates["guideMultiport"]*/}
                                    {/*        || !this.props.downloadStates["guideMultiport"].isLoading) {*/}
                                    {/*        this.props.downloadGuideMultiport(new Date().getTime(), "fr");*/}
                                    {/*    }*/}
                                    
                                    {/*}}>*/}
                                    {/*    <DescriptionIcon style={iconStyle} />*/}
                                    {/*    <div style={textElementStyle}>*/}
                                    {/*        <i ></i> Guide Multiport (FR)*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="documentHover" style={elementStyle} onClick={(e) => {*/}
                                    {/*    if (!this.props.downloadStates["downloadGuideQuotation"]*/}
                                    {/*        || !this.props.downloadStates["downloadGuideQuotation"].isLoading) {*/}
                                    {/*        this.props.downloadGuideQuotation(new Date().getTime());*/}
                                    {/*    }*/}
                                    
                                    {/*}}>*/}
                                    {/*    <DescriptionIcon style={iconStyle} />*/}
                                    {/*    <div style={textElementStyle}>*/}
                                    {/*        <i></i> {getText("DocDownloadGuideQuotation")}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="documentHover" style={elementStyle} onClick={(e) => {
                                        if (!this.props.downloadStates["chargesGlossary"]
                                            || !this.props.downloadStates["chargesGlossary"].isLoading) {
                                            this.props.downloadChargesGlossary(new Date().getTime());
                                        }

                                    }}>
                                        <DescriptionIcon style={iconStyle} />
                                        <div style={textElementStyle}><i></i> Charges glossary</div>
                                    </div>

                                    <div className="documentHover" style={elementStyle} onClick={(e) => {
                                        if (!this.props.downloadStates["portsAndZones"]
                                            || !this.props.downloadStates["portsAndZones"].isLoading) {
                                            this.props.downloadPortsAndZones(new Date().getTime());
                                        }

                                    }}>
                                        <DescriptionIcon style={iconStyle} />
                                        <div style={textElementStyle}><i></i> Ports and zones</div>
                                    </div>
                                </div>
                            }
                        ].concat(_.uniqBy(this.props.firmDocuments
                            .filter(x => x.firmId), x => x.firmId)
                            .map(x => ({
                                title: getFirmName(x.firm),
                                titleIcon: <FolderIcon style={iconStyle} />,
                                tabKey: `client-${x.firmId}`,
                                tabContent: <div style={docStyle}>
                                    {this.props.firmDocuments
                                        .filter(y => y.firmId === x.firmId)
                                        .map(fd =>
                                            <div className="documentHover" style={elementStyle} onClick={(e) => {
                                                if (!this.props.downloadStates[fd.firmDocumentId]
                                                    || !this.props.downloadStates[fd.firmDocumentId].isLoading) {
                                                    this.props.downloadFirmDocument(new Date().getTime(), fd.firmDocumentId);
                                                }

                                            }}>
                                                <DescriptionIcon style={iconStyle} />
                                                <div style={textElementStyle}>
                                                    {(fd.carrierId ? this.props.carriers[fd.carrierId].name + "-" : "")}{fd.name}
                                                </div>
                                            </div>
                                        )}
                                </div>
                            }))).concat(_.uniqBy(this.props.firmDocuments
                                .filter(x => x.carrierId), x => x.carrierId)
                                .map(x => ({
                                    title: this.props.carriers[x.carrierId].name,
                                    titleIcon: <FolderIcon style={iconStyle} />,
                                    tabKey: `carrier-${x.carrierId}`,
                                    tabContent: <div style={docStyle}>
                                        {this.props.firmDocuments
                                            .filter(y => y.carrierId === x.carrierId)
                                            .map(fd =>
                                                <div className="documentHover" style={elementStyle} onClick={(e) => {
                                                    if (!this.props.downloadStates[fd.firmDocumentId]
                                                        || !this.props.downloadStates[fd.firmDocumentId].isLoading) {
                                                        this.props.downloadFirmDocument(new Date().getTime(), fd.firmDocumentId);
                                                    }

                                                }}>
                                                    <DescriptionIcon style={iconStyle} />
                                                    <div style={textElementStyle}>
                                                        {(fd.carrierId ? this.props.carriers[fd.carrierId].name + "-" : "")}{fd.name}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                })))} />
                </div>
            </div>
        );
    }
}

const tabName = "documents";

export default connect((state: ApplicationState) => ({
    ...state.document,

    carriers: state.seed.carriers,
    selectedTab: state.tabs[tabName] ? state.tabs[tabName].selectedTab : "okargo"
}),
    DocumentStore.actionCreators)(Document) as any