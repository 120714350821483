import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as UserStore from '../store/Users';
import { getText } from '../utils/langTexts';
import { getSubscription, getFirmName } from '../utils/utils';
import * as Notifications from "react-notification-system-redux";


type ModeratorUserFormProps = ReduxForm.InjectedFormProps<Api.UserManagementModel, {}, string>
    & ModeratorUserFormOwnProps
    & ModeratorUserFormExternalProps
    & UserStore.UserState
    & ReduxForm.WrappedFieldArrayProps
    & typeof UserStore.actionCreators
    ;

interface ModeratorUserFormOwnProps {
    initialValues: Api.UserManagementModel;
    subscriptions: Array<Api.SubscriptionModel>;
    selectedFirms: Api.SubscriptionsManagemenet[];
    subscription: Api.SubscriptionModel;
    agencies: Array<Api.FirmModel>;
    subOkargoId: number;
}

interface ModeratorUserFormExternalProps {
    onSubmit: (values: Api.UserManagementModel) => Promise<any>;
    mode: "Create" | "Update";
}

type CarrierRatesSettingsType = "Client" | "Company";
interface ModeratorUserFormState {

}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12
}

const labelStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 200,
    textAlign: "right",
    marginRight: 6
}


class ModeratorUsersForm extends React.Component<ModeratorUserFormProps, ModeratorUserFormState> {

    constructor(props: ModeratorUserFormProps) {
        super(props);
    }
    ;
    firmReqValidation = (value: Array<Api.SubscriptionsManagemenet>) => (!value || !value.some(x => x.isSelected)) && "At least one firm must be selected!";

    renderFirms: React.FC<ReduxForm.WrappedFieldArrayProps> = ({ fields, meta: { error } }) => (<div>
        <table style={{

            textAlign: "center",
            verticalAlign: "middle",
            padding: "5x"
        }} >
            <tr>
                <th style={{ width: "200px" }}>Company</th>
                <th style={{ width: "200px" }}>Rates management</th>
                <th style={{ width: "200px" }}>Self-upload</th>
                <th style={{ width: "200px" }}>User management</th>
                <th style={{ width: "200px" }}>Online quotes management</th>
            </tr>
            {this.props.selectedFirms.map((x, index) => <tr >
                <td  >
                    <ReduxForm.Field name={`subscriptionFirms[${index}].isSelected`}
                        validate={[]}
                        labelStyle={{ fontSize: 14, minWidth: 80 }}
                        component={FormField.checkBoxField(x.name)} />
                </td>
                <td  >
                    <ReduxForm.Field name={`subscriptionFirms[${index}].allowRatesManagment`}
                        validate={[]}
                        labelStyle={{ fontSize: 14, }}
                        component={FormField.checkBoxField("")} />
                </td><td>
                    <ReduxForm.Field name={`subscriptionFirms[${index}].allowSelfUpload`}
                        validate={[]}
                        labelStyle={{ fontSize: 14, }}
                        component={FormField.checkBoxField("")} />
                </td><td>
                    <ReduxForm.Field name={`subscriptionFirms[${index}].allowUserManagment`}
                        validate={[]}
                        labelStyle={{ fontSize: 14, }}
                        component={FormField.checkBoxField("")} />
                </td><td>
                    <ReduxForm.Field name={`subscriptionFirms[${index}].allowOnlineQuotes`}
                        validate={[]}
                        labelStyle={{ fontSize: 14, }}
                        component={FormField.checkBoxField("")} />
                </td>
            </tr>
            )}
        </table>

        {error && <span style={{ color: "red" }}>{error}</span>}
    </div>
    );





    public render() {
        return (
            <FormField.BaseForm


                {...this.props} autoComplete="off" className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div style={{ ...fieldStyle }} id="email">
                    <label style={{ ...labelStyle }}>Email: </label>
                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="email"
                                type={"text"}
                                autoComplete="off"
                                validate={[FormField.required]}
                                component={FormField.renderField}
                            />
                        </div>
                    </form>

                </div>
                <div style={{ ...fieldStyle }} id="firstName">
                    <label style={{ ...labelStyle }}>First Name: </label>
                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="firstName"
                                type={"text"}
                                autoComplete="off"
                                validate={[FormField.required]}
                                component={FormField.renderField}
                            />
                        </div>
                    </form>

                </div>
                <div style={{ ...fieldStyle }} id="lastName">
                    <label style={{ ...labelStyle }}>Last name: </label>
                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="lastName"
                                type={"text"}
                                autoComplete="off"
                                validate={[FormField.required]}
                                component={FormField.renderField}
                            />
                        </div>
                    </form>
                </div>

                <div style={{ ...fieldStyle }} id="job">
                    <label style={{ ...labelStyle }}>Job: </label>

                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="job"
                                type={"text"}
                                autoComplete="off"
                                validate={[]}
                                value={5}
                                component={FormField.renderField} />
                        </div>

                    </form>
                </div>

                <div style={{ ...fieldStyle }} id="job">
                    <label style={{ ...labelStyle }}>Phone number: </label>

                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="phone1"
                                type={"text"}
                                autoComplete="off"
                                validate={[]}
                                value={5}
                                component={FormField.renderField} />
                        </div>

                    </form>
                </div>
                <div style={{ ...fieldStyle }}>
                    <label style={{ ...labelStyle }}>Access to: </label>
                    <div style={{}}>

                        <ReduxForm.FieldArray
                            name="subscriptionFirms"
                            component={this.renderFirms}
                            validate={this.firmReqValidation}
                            props={{}}


                        />
                    </div>


                </div>

                <div style={{ ...fieldStyle }}>
                    <div style={{ ...labelStyle }}></div>
                    <div style={{ flex: 1 }}>
                        <button
                            type="submit"
                            style={{ ...Colors.revertColors.main3 }}
                            disabled={this.props.submitting || this.props.pristine}
                            className="btn">
                            {this.props.mode === "Create" && getText("GenCreate")}
                            {this.props.mode === "Update" && getText("GenSaveChanges")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createUser',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(ModeratorUsersForm) as any;
const createSelector = ReduxForm.formValueSelector('createUser')

let updateForm = ReduxForm.reduxForm({
    form: 'updateUser',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(ModeratorUsersForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateUser')

export const CreateModeratorUserForm = connect((state: ApplicationState) => {
    let adminUserFirms = state.account.currentUser.clientModel.agencies.filter(x => x.enabled).map(f => {
        return {
            firmId: f.firmId,
            isSelected: false,
            name: f.name
        }
    });
    return {
        initialValues: {
            subscriptionFirms: adminUserFirms

        },
        selectedFirms: adminUserFirms,
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
    } as ModeratorUserFormOwnProps;
},
    {
        ...UserStore.actionCreators,
    }

)(createForm) as any as React.ComponentClass<ModeratorUserFormExternalProps>

export const UpdateModeratorUserForm = connect((state: ApplicationState) => {

    let user = state.users.entities
        .find(x => x.clientId === state.users.selectedId);
    let adminUserFirms = state.account.currentUser.clientModel.agencies.filter(x => x.enabled).map(f => {
        var sub = user.subscriptionFirms.find(s => s.firmId == f.firmId);
        return {
            firmId: f.firmId,
            isSelected: sub ? true : false,
            name: f.name,
            allowRatesManagment: sub ? (sub.allowRatesManagment) : false,
            allowSelfUpload: sub ? sub.allowSelfUpload : false,
            allowUserManagment: sub ? sub.allowUserManagment : false,
            allowOnlineQuotes: sub ? sub.allowOnlineQuotes : false
        }
    });
    return {
        initialValues: {
            ...user,
            subscriptionFirms: adminUserFirms
            ,
        } || {},
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        selectedFirms: adminUserFirms,
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
    } as ModeratorUserFormOwnProps;
},
    {
        ...UserStore.actionCreators,
    })(updateForm) as any as React.ComponentClass<ModeratorUserFormExternalProps>