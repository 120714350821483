import * as Api from '../api/api';
import * as _ from 'lodash';
import { CarrierOfferState } from '../store/Selection';

export const getAllDeparts = (carrierOffer: Api.CarrierOfferModel): Array<Api.DepartModel> => {
    return (carrierOffer.chargeSet
        && carrierOffer.chargeSet.departs
        && carrierOffer.chargeSet.departs.length !== 0
        ? carrierOffer.chargeSet.departs
        : carrierOffer.routeConfigs.map(x => x.departs).reduce((a, b) => a.concat(b), []));
}

export const getSelectedDepart = (carrierOffer: Api.CarrierOfferModel, carrierOfferState: CarrierOfferState): Api.DepartModel => {
    let departs = carrierOffer.chargeSet
        && carrierOffer.chargeSet.departs
        && carrierOffer.chargeSet.departs.length !== 0
        ? carrierOffer.chargeSet.departs
        : getRouteConfig(carrierOffer, carrierOfferState)?.departs || [];
    return _.sortBy(departs, x => !(carrierOfferState
        && carrierOfferState.selectedDepartId
        && carrierOfferState.selectedDepartId === x.departId))[0];
}

export const routeSourceValue = (source: Api.RouteConfigModelSourceEnum) => {
    switch(source){
        case "CarrierRates":
            return 4;
        case "ManualFromGrid":
            return 3;
        case "ScheduleSystem":
            return 2;
        case "Manual":
            return 1;
    }
}
export const getRouteConfig = (carrierOffer: Api.CarrierOfferModel
    , carrierOfferState: CarrierOfferState): Api.RouteConfigModel => {

    //this methodod must fits also other C# getRouteConfig methods
    //var sortedByLastUpdate = _.sortBy(carrierOffer.routeConfigs, x => x.lastUpdate).reverse(); //sort will take last update like prio
    //sortedByLastUpdate.filter(x => x.routeConfigId)
    //let sortedRcs = _.sortBy(sortedByLastUpdate
    //        .filter(x => x.departs.length !== 0 || (carrierOffer.chargeSet && carrierOffer.chargeSet.departs.length !== 0))
    //    , x => -routeSourceValue(x.source));

  let  sortedRcs = carrierOffer.routeConfigs.filter(x => x.departs.length !== 0 || (carrierOffer.chargeSet && carrierOffer.chargeSet.departs.length !== 0)).sort((a, b) => {
       
         if (a.lastUpdate < b.lastUpdate) return 1;  //descending
         if (a.lastUpdate > b.lastUpdate) return -1;
     if (-routeSourceValue(a.source) < -routeSourceValue(b.source)) return -1;
     if (-routeSourceValue(a.source) > -routeSourceValue(b.source)) return 1;
    
         return 0; // They are equal
     });

    return sortedRcs.find(x => carrierOfferState && x.routeServiceId === carrierOfferState.selectedServiceId)
    || sortedRcs.find(x => carrierOffer.chargeSet && carrierOffer.chargeSet.routeServiceId && carrierOffer.chargeSet.routeServiceId === x.routeServiceId)
    || sortedRcs[0];
}
